<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>MANAGE FAV'S</h5>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-6">
                            <input type='text' class="filter-ngx form-control" placeholder='Search MyFavs'
                                (input)="search($event)" [(ngModel)]="searchTerm" />
                        </div>
                    </div>
                    <br />
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table *ngIf="!showSpinner" [settings]="settings" [source]="productList">
                            </ng2-smart-table>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" [class.d-none]="!showSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->