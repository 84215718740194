<!-- <a role="button" *ngIf="rowData?.isBlocked!= 'Active'" class="text-decoration-none activate-button"
    (click)="click.emit({rowData:rowData, isDelete:false})">Unblock</a>
<a role="button" *ngIf="rowData?.isBlocked== 'Active'" class="text-decoration-none deactivate-button"
    (click)="click.emit({rowData:rowData, isDelete:false})">Block</a>
<a role="button" class="text-decoration-none mx-2 btn-delete"
    (click)="click.emit({rowData:rowData, isDelete:true})">Delete</a> -->

<!-- <a role="button">
    <i class="fa-solid fa-ellipsis-vertical"></i>
</a> -->
<div class="dropdown">
    <a class="dropdown-toggle" role="button" data-toggle="dropdown">
        <i class="fa-solid fa-ellipsis-vertical"></i>
    </a>
    <ul class="dropdown-menu font-weight-bold">
        <li><a role="button" *ngIf="rowData?.isBlocked!= 'Active'" class="text-decoration-none text-dark"
                (click)="click.emit({rowData:rowData, isDelete:false})">Unblock</a></li>
        <li><a role="button" *ngIf="rowData?.isBlocked== 'Active'" class="text-decoration-none text-dark"
                (click)="click.emit({rowData:rowData, isDelete:false})">Block</a></li>
        <li><a role="button" class="text-decoration-none text-dark"
                (click)="click.emit({rowData:rowData, isDelete:true})">Delete</a></li>
    </ul>
</div>