import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { SalesModule } from './components/sales/sales.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { SettingModule } from './components/setting/setting.module';
import { AuthModule } from './components/auth/auth.module';  
import { HttpClientModule } from '@angular/common/http';
import { TestimonialsModule } from './components/testimonials/testimonials.module';
import { CustomersModule } from './components/customers/customers.module';
import { StoresModule } from './components/stores/stores.module';
import { StaticContentModule } from './components/static-content/static-content.module';
import { RestrictInputDirectiveDirective } from './components/restrict-input-directive.directive';
import { StreamingVideosComponent } from './components/streams/streaming-videos/streaming-videos.component';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    RestrictInputDirectiveDirective,
    StreamingVideosComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    SettingModule,
    AuthModule,
    SharedModule,
    ProductsModule,
    SalesModule,
    CouponsModule,
    PagesModule,
    HttpClientModule,
    TestimonialsModule,
    CustomersModule,
    StoresModule,
    StaticContentModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
