import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public order = [];
  public temp = [];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor() {
  }

  public settings = {
    pager: {
      display: true,
      perPage:5,
    },
    actions: {
      position: 'right'
    },
    columns: {
      order_id: {
        title: 'Order Id',
        type: 'html',
      },
      store_name: {
        title: 'Store Name'
      },
      customer_name: {
        title: 'Customer Name'
      },
      items: {
        title: 'Items'
      },
      customer_address: {
        title: 'Customer Address'
      },
      customer_contact_number: {
        title: 'Customer Contact Number'
      },
      seller_contact_number: {
        title: 'Seller Contact Number'
      },
      total_price: {
        title: 'Total Price'
      },
      payment_method: {
        title: 'Payment Method'
      },
      status: {
        title: 'Status'
      },
    },
  };

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.order = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  ngOnInit() {
  }

}
