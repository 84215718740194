<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products Sub Category</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <select class="form-select text-14 form-control w-25 mb-4" (change)="onCategorySelect($event)">
                            <option selected value="all">All</option>
                            <option *ngFor="let item of categories" [value]="item._id">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="btn-popup pull-right mb-4">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                                data-target="#exampleModal" (click)="open(content)">Add Sub Category</button>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title f-w-600" id="exampleModalLabel">{{title}}</h5>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form class="needs-validation" #myForm="ngForm" [formGroup]="addSubCategoryForm">
                                        <div class="form">
                                            <div class="form-group">
                                                <label class="mx-auto mb-1" for="category">Select Category
                                                    :</label>
                                                <select class="form-select text-14 form-control mb-0"
                                                    formControlName="catId" id="category">
                                                    <option selected value="" disabled>Select Category</option>
                                                    <option *ngFor="let item of categories" [value]="item._id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="isSubmitted || (f['catId'].invalid && f['catId'].touched || f['catId'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['catId'].errors?.['required']">Please select
                                                        category.<br></small>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="validationCustom01" class="mb-1">Sub Category Name :</label>
                                                <input class="form-control  mb-0" id="validationCustom01"
                                                    formControlName="subCategoryName" type="text">
                                                <div *ngIf="isSubmitted || (f['subCategoryName'].invalid && f['subCategoryName'].touched || f['subCategoryName'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['subCategoryName'].errors?.['required']">Please enter
                                                        sub category name.<br></small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div class="pr-2">
                                                    <div class="form-group">
                                                        <label for="usSize" class="mb-1">
                                                            US Sizes :</label>
                                                        <div class="input-group">
                                                            <input class="form-control mb-0" #usSize id="usSize"
                                                                formControlName="usSize" type="text">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text px-2 py-0" role="button"
                                                                    id="usSizeAppend"
                                                                    (click)="collectSize(usSize.value, 'add', null, 'US')">
                                                                    <span>Add</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isSubmitted || (f['usSize'].invalid && f['usSize'].touched || f['usSize'].dirty)"
                                                            class="mt-1">
                                                            <small class="text-danger"
                                                                *ngIf="f['usSize'].errors?.['pattern']">
                                                                Please enter valid Size
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <div id="attach-us-size" class="container-box">
                                                    </div>
                                                    <div *ngIf="showUpdate">
                                                        <div id="display-us-size" *ngFor="let size of updateUsSize">
                                                            <div class="size-contain" type="text">
                                                                <span>{{size}}</span>
                                                                <i class="fa-solid fa-trash"
                                                                    (click)="removeSize(size, 'US')"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="pl-2">
                                                    <div class="form-group">
                                                        <label for="ukSize" class="mb-1">UK Sizes :</label>
                                                        <div class="input-group">
                                                            <input class="form-control mb-0" #ukSize id="ukSize"
                                                                formControlName="ukSize" type="text">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text px-2 py-0" role="button"
                                                                    id="ukSizeAppend"
                                                                    (click)="collectSize(ukSize.value, 'add', null, 'UK')">
                                                                    <span>Add</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isSubmitted || (f['ukSize'].invalid && f['ukSize'].touched || f['ukSize'].dirty)"
                                                            class="mt-1">
                                                            <small class="text-danger"
                                                                *ngIf="f['ukSize'].errors?.['pattern']">
                                                                Please enter valid size
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div id="attach-uk-size" class="container-box">
                                                    </div>
                                                    <div *ngIf="showUpdate">
                                                        <div id="display-us-size" *ngFor="let size of updateUkSize">
                                                            <div class="size-contain" type="text">
                                                                <span>{{size}}</span>
                                                                <i class="fa-solid fa-trash"
                                                                    (click)="removeSize(size, 'UK')"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <label for="validationCustom01" class="mb-1">description :</label>
                                                <input class="form-control mb-0" id="validationCustom01"
                                                    formControlName="Description" type="string">
                                                <div *ngIf="isSubmitted || (f['Description'].invalid && f['Description'].touched || f['Description'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['Description'].errors?.['required']">Please enter
                                                        description.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group" *ngIf="!showUpdate">
                                                <label for="SubcategoryImage" class="mb-1"> Add Image</label>
                                                <input type="file" class="form-control mb-0" #imageUploader
                                                    (change)="uploadImage($event)" id="SubcategoryImage"
                                                    formControlName="SubcategoryImage" accept="image/*" />
                                                <div *ngIf="isSubmitted || (f['SubcategoryImage'].invalid && f['SubcategoryImage'].touched || f['SubcategoryImage'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['SubcategoryImage'].errors?.['required']">Please upload
                                                        image
                                                        name.<br></small>
                                                </div>
                                            </div>
                                            <div class="row px-3" *ngIf="showUpdate">
                                                <div class="image-container position-relative d-inline-block mx-2 mb-3">
                                                    <input type="file" #subCategoryImages id="subCategoryImages"
                                                        (change)="uploadImage($event)"
                                                        formControlName="SubcategoryImage" accept="image/*" hidden>
                                                    <div class="h-100 w-100 d-flex justify-content-center image-box align-items-center"
                                                        [ngStyle]="{'background-image': imageLink ? 'url(' + imageLink + ')' : 'url(' + '' + ')' }">
                                                        <app-feather-icons [icon]="'upload'"
                                                            (click)="subCategoryImages.click()" class="text-secondary">
                                                        </app-feather-icons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" *ngIf="!showUpdate"
                                        (click)="addUpdateSubCategory('add')">Save</button>
                                    <button type="button" class="btn btn-primary" *ngIf="showUpdate"
                                        (click)="addUpdateSubCategory('update')">Update</button>
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                        (click)="modal.dismiss('Cross click')">{{ showUpdate?'Cancel':'Close' }}</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table *ngIf="!showSpinner" [settings]="settings" [source]="sub_categories">
                            </ng2-smart-table>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" [class.d-none]="!showSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->