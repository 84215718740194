import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StaticContentRoutingModule } from './static-content-routing.module';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


@NgModule({
  declarations: [FaqsComponent, ContactUsComponent, TermsAndConditionsComponent, PrivacyPolicyComponent],
  imports: [
    CommonModule,
    StaticContentRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class StaticContentModule { }
