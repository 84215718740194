import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { MaterialDB } from 'src/app/shared/tables/Term';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { StaticContentService } from '../service/static-content.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  addTermsAndConditionsForm: FormGroup;
  closeResult: string;
  termsAndConditions = [];
  isSubmitted = false;
  public term: string = '';
  public conditionId: string = '';
  public showButton: boolean = false;
  public readonly destroyer$: Subject<void> = new Subject();
  description: any;
  constructor(private formBuilder: FormBuilder, private staticContentService: StaticContentService) {
  }

  setStyle(style: string) {
    let bool = document.execCommand(style, false, null);
  }

  ngOnInit(): void {
    this.createForm();
    this.getTermsAndConditions();
  }

  createForm() {
    this.addTermsAndConditionsForm = this.formBuilder.group({
      description: ['', [Validators.required]]
    })
  }

  get f() {
    return this.addTermsAndConditionsForm.controls;
  }

  addTerms() {
    let param = {
      term: document.getElementById('editor').innerHTML
    }
    this.isSubmitted = true;
    if (this.isSubmitted && document.getElementById('editor').innerHTML != '') {
      this.staticContentService.addTermsAndConditions(param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.body.success == true) {
            Swal.fire({
              icon: 'success',
              text: response.body.message,
            });
            this.getTermsAndConditions();
            this.isSubmitted = false;
          }
        }, error => {
          Swal.fire({
            icon: 'error',
            text: error,
          })
        })
    }
  }
  getTermsAndConditions() {
    this.staticContentService.getTermsAndConditions().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          if (response.data.length != 0) {
            this.description = response.data[0].description
            this.conditionId = response.data[0]._id
            this.showButton = true;
          }
        }
      }, error => {
        console.error(error);
      })
  }
  updateTermsAndCondition() {
    let param = {
      term: document.getElementById('editor').innerHTML,
    }
    if (document.getElementById('editor').innerHTML != '') {
      this.staticContentService.updateTermsAndConditions(this.conditionId, param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.body.success == true) {
            Swal.fire({
              icon: 'success',
              text: response.body.message,
            });
            this.getTermsAndConditions();
          }
        }, error => {
          Swal.fire({
            icon: 'error',
            text: error,
          })
        })
    }
  }


}
