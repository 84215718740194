import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSubCategoryModel } from 'src/app/shared/models/product';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Category, ProductService, SubCategory } from '../service/product.service';
import { SubCategoriesService } from '../sub-categories.service';
import { SubSubCategoriesService } from '../sub-sub-categories.service';
import { CommonLoaderComponent } from 'src/app/shared/components/common-loader/common-loader.component';
import { CustomButtonsComponent } from 'src/app/shared/components/custom-buttons/custom-buttons.component';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-sub-sub-category',
  templateUrl: './sub-sub-category.component.html',
  styleUrls: ['./sub-sub-category.component.scss'],
})
export class SubSubCategoryComponent implements OnInit, OnDestroy {
  closeResult: any;
  addSubSubCategoryForm: FormGroup;
  isSubmitted: boolean = false;
  imageFile;
  imageLink;
  subCategoriesByCatId: SubCategory[] = [];
  categories: Category[] = [];
  formSubCategories: SubCategory[] = []
  subSubCategories: SubSubCategoryModel[] = [];
  showSpinner: boolean = true;
  @ViewChild('content')
  content!: ElementRef;
  title = 'Add Sub Sub Category';
  rowData: any
  showUpdate: boolean = false;
  images = [];
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private subSubCategoryService: SubSubCategoriesService,
    private subCategoriesService: SubCategoriesService,
    private productService: ProductService
  ) { }

  public settings = {
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      name: {
        title: 'Sub Sub Category'
      },
      image: {
        title: 'Picture',
        type: 'html',
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'edit')
              this.editSubSubCategory(row);
            if (row.action == 'delete')
              this.deleteSubSubCategory(row.rowData);
          });
        }
      },
    },
  };

  ngOnInit(): void {
    this.setForm()
    this.getCategory()
    this.getSubSubCategories();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setForm() {
    this.addSubSubCategoryForm = this.formBuilder.group({
      subSubCategoryName: ['', Validators.required],
      subCategory: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      productImage: ['', Validators.required],
    })
  }

  get f() {
    return this.addSubSubCategoryForm.controls;
  }

  open(content, rowData?) {
    if (rowData)
      this.patchValues(rowData)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.resetForm();
    });
  }

  resetForm() {
    this.f['productImage'].setValidators([Validators.required]);
    this.addSubSubCategoryForm.reset({
      subCategory: '',
      category: '',
    })
    this.showUpdate = false;
    this.imageLink = ''
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  patchValues(rowData?) {
    this.getSubCategorybyCatId(rowData.category[0]._id, 'form');
    this.imageLink = rowData?.imageUrl;
    this.addSubSubCategoryForm.patchValue({
      subSubCategoryName: rowData?.name,
      subCategory: rowData?.subcategory[0]._id,
      category: rowData?.category[0]._id,
      description: rowData?.description,
      // productImage:
    })
  }

  getCategory() {
    this.http.get(environment.apiUrl + 'category/get').subscribe(response => {
      this.categories = response['data'];
    })
  }

  onCategorySelect(event: any) {
    if
      (event.target.value == 'all') {
      this.getSubCategories('table');
      document.getElementById('selectSubCategory')['value'] = ''
      this.getSubSubCategories();
    }
    else
      this.getSubCategorybyCatId(event.target.value, 'table');
  }
  onFormCategorySelect(event: any) {
    if
      (event.target.value == 'all') this.getSubCategories('form');
    else
      this.getSubCategorybyCatId(event.target.value, 'form');
  }

  getSubCategorybyCatId(catId: string, value: string) {
    this.subCategoriesService.getSubCategoriesFromCatId(catId).subscribe(response => {
      if (value == 'table')
        this.subCategoriesByCatId = response['data'];
      if (value == 'form')
        this.formSubCategories = response['data'];
    })
  }

  getSubCategories(value: string) {
    this.http.get(environment.apiUrl + 'subcategory/get').subscribe(response => {
      if (value == 'table')
        this.subCategoriesByCatId = response['data']
      if (value == 'form')
        this.formSubCategories = response['data'];
    })
  }

  onSubCategorySelect(event: any) {
    this.subSubCategoryService.getSubSubCategoriesBySubId(event.target.value).subscribe(response => {
      this.subSubCategories = response.data;
      if (response.success == true) {
        const newArray = []
        response.data.forEach((element, i) => {
          let param = {
            sr_no: i + 1,
            category: element.catId[0]?.name || 'no category',
            subcategory: element.subCatId[0]?.name || 'no sub category',
            name: element.name,
            // image: element && element.image ? "<img src=" + element.image + " class='imgTable'>" : "no image",
            image: element.image != '' ? `<img src=${element.image} class='imgTable'>` : "<img  src=" + 'assets/images/no-img-available.jpg' + " class='imgTable'>",
            imageUrl: element?.image
          }
          newArray.push(param)
        })

        if (newArray.length) {
          this.subSubCategories = newArray;
        }
        // this.sub_categories = newArr;
      }
    })
  }

  uploadImage(event: any) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imageFile = event.srcElement.files[0];
      this.imageLink = reader.result;
      // this.images.push(reader.result);
    }
  }

  addSubSubCategory(value?) {
    this.isSubmitted = true;
    let formData = new FormData;
    formData.append('name', this.f['subSubCategoryName'].value)
    formData.append('subCatId', this.f['subCategory'].value)
    formData.append('catId', this.f['category'].value)
    formData.append('description', this.f['description'].value)
    if (this.imageFile)
      formData.append('image', this.imageFile)
    if (this.isSubmitted && this.addSubSubCategoryForm.valid) {
      this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
      if (value == 'add') {
        this.subSubCategoryService.createSubSUbCategory(formData)
          .subscribe(response => {
            if (response.body.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.getSubSubCategories();
              this.resetForm();
              this.modalService.dismissAll();
            }
          })
      } else if (value == 'update') {
        this.subSubCategoryService.updateSubSubCategory(this.rowData?.rowData?._id, formData)
          .subscribe(response => {
            if (response.body.success == true) {
              this.modalService.dismissAll();
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              // this.cdr.detectChanges();
              this.getSubSubCategories();
              this.resetForm();
              this.modalService.dismissAll();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
    }
  }

  getSubSubCategories() {
    setTimeout(() => this.showSpinner = false, 800);
    this.subSubCategoryService.getSUbSUbCategories()
      .subscribe(response => {
        if (response.success == true) {
          const newArray = []
          response.data.forEach((element, i) => {
            let param = {
              sr_no: i + 1,
              category: element.catId || 'no category',
              subcategory: element.subCatId || 'no sub category',
              name: element.name,
              image: element && element.image ? "<img src=" + element.image + " class='imgTable'>" : "no image",
              _id: element && element?._id,
              imageUrl: element?.image,
              images: element?.image,
              description: element?.description
            }
            this.imageLink = element?.image;
            newArray.push(param)
          })

          if (newArray.length) {
            this.subSubCategories = newArray;
          }
        }
      }, error => {
        console.error(error);
      })
  }

  editSubSubCategory(rowData?) {
    this.showUpdate = true;
    this.rowData = rowData;
    this.f['productImage'].clearValidators();
    // this.images = [rowData?.rowData?.images];
    this.title = 'Update Sub Sub Category';
    this.open(this.content, rowData.rowData)
  }

  // removeImage(i) {
  //   this.images.splice(i, 1);
  // }

  //delete by id
  deleteSubSubCategory(rowData?: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this subsubcategory?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.subSubCategoryService.deleteSubSubCategory(rowData._id)
          .subscribe(response => {
            if (response.body.success == true) {
              this.cdr.detectChanges();
              this.subSubCategories = this.subSubCategories.filter(subsubcategory => subsubcategory._id !== rowData._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'SubSubCategory has been deleted.',
                'success'
              )
              this.getSubSubCategories();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled'
        )
      }
    })
  }

}
