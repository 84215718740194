import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ProductService } from '../service/product.service';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonLoaderComponent } from 'src/app/shared/components/common-loader/common-loader.component';
import { CustomButtonsComponent } from 'src/app/shared/components/custom-buttons/custom-buttons.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-size',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.scss']
})
export class SizeChartComponent implements OnInit, OnDestroy {
  bannerFile
  bannerLink
  closeResult
  banners = [];
  categories = []
  isSubmitted = false;
  // images: string[] = [];
  addSizeChartForm: FormGroup;
  showSpinner: boolean = true;
  @ViewChild('content')
  content!: ElementRef;
  title = 'Add Size Chart';
  rowData: any
  showUpdate: boolean = false;
  images = [];
  imageLink: any;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private productService: ProductService,) { }
  private sanitizer: DomSanitizer

  public settings = {
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right',
      add: false,
      delete: false,
      edit: false
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      Size: {
        title: 'Images',
        type: 'html',
      },
      title: {
        title: 'Title '
      },
      category: {
        title: 'Category'
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'edit')
              this.editSizeChart(row);
            if (row.action == 'delete')
              this.deleteSizeChart(row.rowData);
          });
        }
      },
    },
  };

  ngOnInit(): void {
    this.setForm();
    this.getCategory()
    this.getSizeCharts();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setForm() {
    this.addSizeChartForm = this.formBuilder.group({
      banner: ['', [Validators.required]],
      catId: ['', [Validators.required]],
      title: ['', [Validators.required]]
    })
  }

  get f() {
    return this.addSizeChartForm.controls;
  }

  editSizeChart(rowData?) {
    this.showUpdate = true;
    this.rowData = rowData.rowData;
    this.f['banner'].clearValidators();
    // this.images = [rowData?.rowData?.images];
    this.title = 'Update Size Chart';
    this.open(this.content, rowData)
  }

  // removeImage(i) {
  //   this.images.splice(i, 1);
  // }

  open(content, rowData?): void {
    if (rowData)
      this.patchValues(rowData);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.title = 'Add Size Chart';
      this.showUpdate = false;
      this.f['banner'].setValidators([Validators.required]);
      this.addSizeChartForm.reset();
    });
  }

  patchValues(rowData?) {
    this.addSizeChartForm.patchValue({
      // banner: ,
      catId: rowData?.rowData?.catId?._id,
      title: rowData?.rowData?.title,
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getSizeCharts() {
    setTimeout(() => this.showSpinner = false, 800);
    this.productService.getSize()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          const newArray = []
          response.data.forEach((element, i) => {
            let param = {
              sr_no: i + 1,
              // Size: "<img src=" + element?.image + " class='imgTable'>",
              Size: element.image != '' ? `<img src=${element.image} class='imgTable'>` : "<img  src=" + 'assets/images/no-img-available.jpg' + " class='imgTable'>",
              category: element?.catId?.name,
              _id: element?._id,
              title: element?.title || 'not found',
              catId: element?.catId,
              imageUrl: element?.image,
              images: element?.image
            }
            this.imageLink = element?.image
            newArray.push(param)
            this.banners.push(param);
          })

          if (newArray.length) {
            this.banners = newArray;
          }
        }
      })
  }

  uploadBanner(event) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.bannerFile = event.srcElement.files[0];
      this.imageLink = reader.result;
      // this.images.push(reader.result);
    }
  }

  getCategory() {
    this.productService.getCategory()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          this.categories = response.data;
          response.data.forEach((item, i) => {
            const obj = {} as any
            obj.value = item._id;
            obj.title = item.name;
            // this.settings.columns.category.editor.config.list.push(obj);
            this.settings = Object.assign({}, this.settings);
          })
        }
      }, error => {
        console.error(error);
      })
  }

  onCategorySelect(event: any) {
    if (event.target.value == 'all') this.getSizeCharts();
    else this.getSizeChartByCatId(event.target.value)
  }

  getSizeChartByCatId(catId: string) {
    this.productService.getSizeChartByCatId(catId)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          let result = [response.data];
          const newArray = []
          response.data.forEach((element, i) => {
            let param = {
              sr_no: i + 1,
              Size: "<img src=" + element.image + " class='imgTable'>",
              category: element.catId?.name,
              _id: element._id,
              title: element?.title || 'not found',
              images: element?.image
            }
            newArray.push(param)
            this.banners.push(param);
          })
          if (newArray.length) {
            this.banners = newArray;
          }
        }
      })
  }

  addSizeChart(value?) {
    let formData = new FormData;
    formData.append('catId', this.addSizeChartForm.value.catId);
    formData.append('title', this.addSizeChartForm.value.title);
    if (this.bannerFile)
      formData.append('image', this.bannerFile);
    this.isSubmitted = true;
    if (this.isSubmitted == true && this.addSizeChartForm.valid) {
      this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
      if (value == 'add') {
        this.productService.addSizeChart(formData)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.getSizeCharts();
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.addSizeChartForm.reset();
              this.modalService.dismissAll();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (value == 'update') {
        this.productService.updateSizeChart(this.rowData._id, formData)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.modalService.dismissAll();
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.getSizeCharts();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
    }
  }

  deleteSizeChart(rowData?) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this Size Chart?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteSizeChart(rowData._id)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.banners = this.banners.filter(banner => banner._id !== rowData._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'Size Chart has been deleted.',
                'success'
              )
              this.getSizeCharts();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled'
        )
      }
    })
  }

}
