<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products Sub Sub Category</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex w-50 justify-content-between">
                            <select class="form-select text-14 form-control w-50 mr-2 mb-4"
                                (change)="onCategorySelect($event)">
                                <option selected value="" disabled>Select Category</option>
                                <option value="all">All</option>
                                <option *ngFor="let item of categories" [value]="item._id">
                                    {{item.name}}
                                </option>
                            </select>
                            <select id="selectSubCategory" class="form-select text-14 form-control w-50 mb-4"
                                (change)="onSubCategorySelect($event)">
                                <option selected value="" disabled>Select Sub Category</option>
                                <option *ngFor="let item of subCategoriesByCatId" [value]="item._id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                        <div class="btn-popup pull-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                                data-target="#exampleModal" (click)="open(content)">Add Sub Sub Category</button>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title f-w-600" id="exampleModalLabel">{{title}}</h5>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form class="needs-validation" [formGroup]="addSubSubCategoryForm">
                                        <div class="form">
                                            <div class="form-group">
                                                <label class="mx-auto mb-1" for="category">Select Category
                                                    :</label>
                                                <select class="form-select text-14 form-control"
                                                    (change)="onFormCategorySelect($event)" formControlName="category"
                                                    id="category">
                                                    <option selected value="" disabled>Select Category</option>
                                                    <option value="all">All</option>
                                                    <option *ngFor="let item of categories" [value]="item._id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="isSubmitted || (f['category'].invalid && f['category'].touched || f['category'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['category'].errors?.['required']">Please select
                                                        category.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="subCategory" class="mb-1">Select Sub Category :</label>
                                                <select class="form-select text-14 form-control"
                                                    formControlName="subCategory" id="subCategory">
                                                    <option selected value="" disabled>Select Sub Category</option>
                                                    <option *ngFor="let item of formSubCategories" [value]="item._id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="isSubmitted || (f['subCategory'].invalid && f['subCategory'].touched || f['subCategory'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['subCategory'].errors?.['required']">Please select sub
                                                        category.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="name" class="mb-1">Sub Sub Category Name :</label>
                                                <input class="form-control" id="name"
                                                    formControlName="subSubCategoryName" type="text">
                                                <div *ngIf="isSubmitted || (f['subSubCategoryName'].invalid && f['subSubCategoryName'].touched || f['subSubCategoryName'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['subSubCategoryName'].errors?.['required']">Please
                                                        enter
                                                        sub category name.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="mx-auto mb-1" for="description">Description</label>
                                                <div class="input-group">
                                                    <textarea class="form-control border text-14 form-control-lg"
                                                        maxlength="150" formControlName="description" id="description"
                                                        rows="4" placeholder="Description"></textarea>
                                                </div>
                                                <div *ngIf="isSubmitted || (f['description'].invalid && f['description'].touched || f['description'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['description'].errors?.['required']">Please
                                                        enter description.<br></small>
                                                </div>
                                            </div>

                                            <div class="form-group" *ngIf="!showUpdate">
                                                <label for="productImage" class="mb-1"> Add Image</label>
                                                <input type="file" class="form-control" (change)="uploadImage($event)"
                                                    #productImage id="productImage" formControlName="productImage"
                                                    accept="image/*" />
                                                <div *ngIf="isSubmitted || (f['productImage'].invalid && f['productImage'].touched || f['productImage'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['productImage'].errors?.['required']">Please upload
                                                        image
                                                        name.<br></small>
                                                </div>
                                            </div>

                                            <div class="row px-3" *ngIf="showUpdate">

                                                <div class="image-container position-relative d-inline-block mx-2 mb-3">
                                                    <input type="file" #subCategoryImage id="subCategoryImage"
                                                        (change)="uploadImage($event)" formControlName="productImage"
                                                        accept="image/*" hidden>
                                                    <div class="h-100 w-100 d-flex justify-content-center image-box align-items-center"
                                                        [ngStyle]="{'background-image': imageLink ? 'url(' + imageLink + ')' : 'url(' + '' + ')' }">
                                                        <app-feather-icons [icon]="'upload'"
                                                            (click)="subCategoryImage.click()" class="text-secondary">
                                                        </app-feather-icons>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="myImg"></div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" *ngIf="!showUpdate"
                                        (click)="addSubSubCategory('add')">Save</button>
                                    <button type="button" class="btn btn-primary" *ngIf="showUpdate"
                                        (click)="addSubSubCategory('update')">Update</button>
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                        (click)="modal.dismiss('Cross click')">{{ showUpdate?'Cancel':'Close'
                                        }}</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table *ngIf="!showSpinner" [settings]="settings" [source]="subSubCategories">
                            </ng2-smart-table>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" [class.d-none]="!showSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->