import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Category, ProductService } from '../service/product.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SubCategoriesService } from '../sub-categories.service';
import { CommonLoaderComponent } from 'src/app/shared/components/common-loader/common-loader.component';
import { CustomButtonsComponent } from 'src/app/shared/components/custom-buttons/custom-buttons.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public sub_categories = [];
  public title: string = 'Add Sub Category';
  addSubCategoryForm: FormGroup;
  categories: Array<Category> = [];
  isSubmitted = false;
  imageFile: any;
  imageLink: any;
  showSpinner: boolean = true;
  counter: number = 0;
  ukSizes: any[] = [];
  usSizes: any[] = [];
  sizeType: any[] = ['US', 'UK'];
  sizeFormats: any[] = [];
  rowData: any;
  updateUkSize = [];
  updateUsSize = [];
  showUpdate: boolean = false;
  public readonly destroyer$: Subject<void> = new Subject();
  @ViewChild('content')
  content!: ElementRef;
  @ViewChild('imageUploader') imageUploader: ElementRef;
  myForm: NgForm;
  images = [];
  constructor(
    private modalService: NgbModal,
    private productService: ProductService,
    private formbuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private subCategoryService: SubCategoriesService,
    private http: HttpClient
  ) { }

  open(content, rowData?) {
    if (rowData) {
      this.patchValues(rowData)
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.resetForm();
    });
  }

  resetForm() {
    this.f['SubcategoryImage'].setValidators([Validators.required]);
    this.addSubCategoryForm.reset({
      catId: '',
    });
    this.showUpdate = false;
    this.imageLink = '';
    this.ukSizes = [];
    this.usSizes = [];
    this.updateUkSize = [];
    this.updateUsSize = [];
    this.sizeFormats = [];
    this.modalService.dismissAll();
    this.title = 'Add Sub Category';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  uploadImage(event: any) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imageFile = event.srcElement.files[0];
      this.imageLink = reader.result;
    }
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    edit: {
      confirmSave: false,
    },
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      image: {
        title: 'Picture',
        type: 'html',
      },
      subcategory: {
        title: 'Sub Category Name'
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'edit')
              this.editSubCategory(row.rowData);
            if (row.action == 'delete')
              this.deleteSubCategory(row.rowData);
          });
        }
      },
    },
  };

  ngOnInit() {
    this.createForm();
    this.getCategory();
    this.getSubCategory();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  createForm() {
    this.addSubCategoryForm = this.formbuilder.group({
      subCategoryName: ['', [Validators.required]],
      catId: ['', [Validators.required]],
      Description: ['', [Validators.required]],
      SubcategoryImage: ['', [Validators.required]],
      ukSize: ['', [Validators.pattern('^[a-zA-Z0-9 \d*\.\d]{1,20}$')]],
      usSize: ['', [Validators.pattern('^[a-zA-Z0-9 \d*\.\d]{1,20}$')]]
    })
  }

  get f() {
    return this.addSubCategoryForm.controls;
  }

  patchValues(rowData?) {
    rowData.sizeFormats?.forEach(sizeFormat => {
      this.displaySizes(sizeFormat);
    });
    this.imageLink = rowData.imageUrl
    this.addSubCategoryForm.patchValue({
      subCategoryName: rowData?.subcategory,
      catId: rowData?.catId[0]._id,
      Description: rowData?.description,
      ukSize: '',
      usSize: ''
    });
  }

  displaySizes(sizeFormats?) {
    switch (sizeFormats.sizeType) {
      case 'US':
        sizeFormats.sizes.forEach(element => {
          this.usSizes.push(element);
          this.updateUsSize.push(element);
        });
        break;
      case 'UK':
        sizeFormats.sizes.forEach(element => {
          this.ukSizes.push(element);
          this.updateUkSize.push(element);
        });
        break;
    }
  }

  getCategory() {
    this.productService.getCategory().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          this.categories = response.data;
          response.data.forEach((item, i) => {
            const obj = {} as any
            obj.value = item._id;
            obj.title = item.name;
            this.settings = Object.assign({}, this.settings);
          })
        }
      }, error => {
        console.error(error);
      })
  }

  onCategorySelect(event: any) {
    if (event.target.value == 'all') this.getSubCategory();
    else this.getSubCategoriesByCatId(event.target.value);
  }

  getSubCategoriesByCatId(catId: string) {
    this.subCategoryService.getSubCategoriesFromCatId(catId).subscribe(response => {
      if (response['success'] == true) {
        const newArray = []
        response['data'].forEach((element, i) => {
          const obj = {
            subcategory: element.name,
            sr_no: i + 1,
            image: element.image != '' ? `<img src=${element.image} class='imgTable'>` : "<img  src=" + 'assets/images/no-img-available.jpg' + " class='imgTable'>",
            description: element?.description || 'no description',
            _id: element._id,

            catId: [element?.catId],
            imageUrl: element?.image,
            images: element?.image,
            sizeFormats: element?.sizeFormats
          }
          newArray.push(obj)
        })
        this.sub_categories = newArray;
      }
    })
  }

  getSubCategory() {
    setTimeout(() => this.showSpinner = false, 800);
    this.productService.getSubCategory().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success) {
          const newArray = []
          response.data.forEach((element, i) => {
            const obj = {
              catId: element?.catId,
              subcategory: element.name,
              sr_no: i + 1,
              imageUrl: element?.image,
              images: element?.image,
              image: element && element.image ? "<img src=" + element.image + " class='imgTable'>" : "no image",
              description: element?.description || 'no description',
              _id: element?._id,
              sizeFormats: element?.sizeFormats
            }
            this.imageLink = element?.image;
            newArray.push(obj)
          })
          this.sub_categories = newArray;
        }

      }, error => {
        console.error(error);
      })
  }

  addUpdateSubCategory(value?) {
    let formData = new FormData;
    formData.append('catId', this.addSubCategoryForm.value.catId)
    formData.append('name', this.addSubCategoryForm.value.subCategoryName)
    formData.append('description', this.addSubCategoryForm.value.Description)
    if (this.imageFile)
      formData.append('image', this.imageFile)
    if (this.ukSizes.length > 0 && this.usSizes.length > 0)
      this.sizeFormats.push([this.usSizes, this.sizeType[0]], [this.ukSizes, this.sizeType[1]]);
    if (this.ukSizes.length == 0 || this.usSizes.length == 0) {
      Swal.fire({
        icon: 'error',
        text: 'You missed the sizes',
      })
    }
    this.sizeFormats.forEach((sizeFormat, i) => {
      formData.append(`sizeFormats[${i}][sizes]`, sizeFormat[0]);
      formData.append(`sizeFormats[${i}][sizeType]`, sizeFormat[1])
    })
    this.isSubmitted = true;
    if (this.addSubCategoryForm.valid && this.isSubmitted == true && this.ukSizes.length != 0 && this.usSizes.length != 0) {
      this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
      if (value == 'add') {
        this.productService.addSubCategory(formData).pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.getSubCategory();
              this.resetForm()
              this.modalService.dismissAll();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
      else if (value == 'update') {
        this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
        this.productService.updateSubCategory(this.rowData._id, formData)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.resetForm()
              this.getSubCategory();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
    }
  }
  // put category
  editSubCategory(rowData?: any) {
    this.showUpdate = true;
    this.rowData = rowData;
    this.f['SubcategoryImage'].clearValidators();
    this.title = 'Update Sub Category';
    this.open(this.content, rowData)
  }
  //delete by id
  deleteSubCategory(rowData?: any) {
    this.rowData = rowData;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this subcategory?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteSubCategory(rowData?._id).pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.cdr.detectChanges();
              this.sub_categories = this.sub_categories.filter(subcategory => subcategory._id !== rowData?._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'SubCategory has been deleted.',
                'success'
              )
              this.getSubCategory();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
        )
      }
    })
  }

  public collectSize(newValue: any, from: string, oldValue?, country?) {
    if (newValue != '') {
      this.counter = this.counter + 1
      const attachUKSize = document.getElementById('attach-uk-size');
      const attachUSSize = document.getElementById('attach-us-size');
      let ele = document.createElement('div');
      let newDiv = document.createElement('div');
      ele.textContent = newValue;
      ele.setAttribute('class', 'size-contain')
      ele.setAttribute('id', 'size-box' + this.counter);
      ele.innerHTML = `<span>${newValue}</span>`;
      newDiv.innerHTML = `<i id='editIcon${this.counter}' class="fa-solid fa-pencil"></i>` + `<i id='deleteIcon${this.counter}' class="fa-solid fa-trash"></i>`;
      switch (country) {
        case 'UK': attachUKSize.appendChild(ele);
          break;
        case 'US': attachUSSize.appendChild(ele);
          break;
      }
      document.getElementById('size-box' + this.counter).appendChild(newDiv);
      document.getElementById('editIcon' + this.counter).style.cursor = 'pointer';
      document.getElementById('deleteIcon' + this.counter).style.cursor = 'pointer';
      if (from == 'add') {
        switch (country) {
          case 'UK': this.ukSizes.push(newValue)
            this.addSubCategoryForm.controls.ukSize.setValue('');
            break;
          case 'US': this.usSizes.push(newValue);
            this.addSubCategoryForm.controls.usSize.setValue('');
            break;
        }
      } else if (from == 'update') {
        switch (country) {
          case 'UK':
            this.ukSizes.forEach((element, index) => {
              if (element == oldValue) this.ukSizes.splice(index, 1);
            });
            this.ukSizes.push(newValue);
            this.addSubCategoryForm.controls.ukSize.setValue('');
            break;
          case 'US':
            this.usSizes.forEach((element, index) => {
              if (element == oldValue) this.usSizes.splice(index, 1);
            });
            this.usSizes.push(newValue);
            this.addSubCategoryForm.controls.usSize.setValue('');
            break;
        }
      }
      this.addEvent(newValue, this.counter, country);
    }
  }

  private addEvent(value: any, counter, country?) {
    let thisObj = this
    const attachUKSize = document.getElementById('attach-uk-size');
    const attachUSSize = document.getElementById('attach-us-size');
    document.addEventListener('click', function (e: any) {
      if (e.target && e.target.id == 'editIcon' + counter) {
        let old_element = document.getElementById('size-box' + counter)
        old_element.remove();
        let newele = document.createElement('input');
        newele.setAttribute('type', 'text');
        newele.setAttribute('class', 'form-control');
        newele.setAttribute('id', 'new-form-control' + counter);
        newele.value = value;
        switch (country) {
          case 'UK':
            attachUKSize.appendChild(newele)
            break;
          case 'US':
            attachUSSize.appendChild(newele)
            break;
        }
        let formControlId = document.getElementById('new-form-control' + counter);
        thisObj.addEventToFormControl(formControlId, counter, value, country);
      } else if (e.target && e.target.id == 'deleteIcon' + counter) {
        switch (country) {
          case 'UK':
            thisObj.ukSizes.forEach((element, index) => {
              if (element == value) thisObj.ukSizes.splice(index, 1);
            });
            break;
          case 'US':
            thisObj.usSizes.forEach((element, index) => {
              if (element == value) thisObj.usSizes.splice(index, 1);
            });
            break;
        }
        let old_element = document.getElementById('size-box' + counter)
        old_element.remove();
      }
    });
  }

  removeSize(size?, country?) {
    switch (country) {
      case 'US':
        this.usSizes.forEach((element, index) => {
          if (element == size) {
            this.usSizes.splice(index, 1);
            this.updateUsSize.splice(index, 1);
          }
        });
        break
      case 'UK':
        this.ukSizes.forEach((element, index) => {
          if (element == size) {
            this.ukSizes.splice(index, 1);
            this.updateUkSize.splice(index, 1);
          }
        });
        break
    }

  }

  addEventToFormControl(formControlId, counter, oldValue, country?) {
    let thisObj = this;
    document.addEventListener('keyup', function (e: any) {
      if (e.isComposing || e.keyCode === 13) {
        if (e.target && e.target.id == 'new-form-control' + counter) {
          let editedFormControl: any = document.getElementById('new-form-control' + counter)
          thisObj.collectSize(e.target.value, 'update', oldValue, country);
          editedFormControl.remove();
        }
      }
    });
  }

}
