import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
  providedIn: 'root'
})
export class SubSubCategoriesService {

  constructor(private http: HttpService, private urlsService: UrlsService) { }

  getSUbSUbCategories() {
    return this.http.getData(this.urlsService.getSubSubCategory);
  }

  getSubSubCategoryByCatId(catId: string) {
    return this.http.getData(this.urlsService.getSubSubCategoryByCatId + catId);
  }

  getSubSubCategoriesBySubId(subId: string) {
    return this.http.getData('subsubcategory/getbysubcatid/' + subId);
  }

  createSubSUbCategory(data: any) {
    return this.http.postFormData(this.urlsService.createSubSubCategory, data);
  }

  updateSubSubCategory(id: string, data: any) {
    return this.http.putFormData(`${this.urlsService.updateSubSubCategory}${id}`, data);
  }

  deleteSubSubCategory(id: string) {
    return this.http.deteleData(`${this.urlsService.deleteSubSUbCategory}${id}`);
  }

}
