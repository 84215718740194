import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private urlsService: UrlsService, private http: HttpService) { }


  addStore(data) {
    // let url = `${this.apiUrl}create`;
    // return this.http.postData(url, fd).pipe(catchError(this.handleError('addStore', null)));
    return this.http.postData(this.urlsService.createStore, data);
  }
  getStore() {
    return this.http.getData(this.urlsService.getStore);
  }
  updateStore(id, data) {
    return this.http.putData(this.urlsService.updateStore + id,data);
  }
  deletStore(id) {
    return this.http.deteleData(this.urlsService.deleteStore + id);
  }

}
