import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    public showButton = new BehaviorSubject<string>('');
    public buttonValue = new BehaviorSubject<string>('');

    constructor(private urlsService: UrlsService, private http: HttpService) { }

    addCustomer(data: any) {
        return this.http.postData(this.urlsService.addCustomer, data);
    }
    getCustomer(param?) {
        return this.http.getData(this.urlsService.getCustomer);
    }

    updateCustomer(customerId: string, data: any) {
        return this.http.putData(this.urlsService.updateCustomer + customerId, data);
    }

    deleteCustomer(customerId: string) {
        return this.http.deteleData(this.urlsService.deleteCustomer + customerId);
    }

    blockUser(userId: string) {
        return this.http.getData('user/block/' + userId);
    }

    unblockUser(userId: string) {
        return this.http.getData('user/unblock/' + userId);
    }

}

export interface Customer {
    question: string,
    answer: string,
    _id: string
}


