import { Component, OnInit } from '@angular/core';
import { StreamService } from '../stream.service';

@Component({
  selector: 'app-streaming-videos',
  templateUrl: './streaming-videos.component.html',
  styleUrls: ['./streaming-videos.component.scss']
})
export class StreamingVideosComponent implements OnInit {
  pageNo: number = 1;
  perPage: number = 5;
  allStreams: Array<any> = [];
  hasMoreData = true;
  constructor(private streamService: StreamService) { }

  ngOnInit(): void {
    this.getStreams();
  }

  loadMoreList() {
    this.pageNo += 1
    this.getStreams();
  }

  getStreams() {
    this.streamService.getStreamVideos(this.pageNo, this.perPage).subscribe(response => {
      if (response.success == true) {
        this.allStreams = this.allStreams.concat(response.data.streamingData);
        if (response.data.paginator.totalDocs === this.allStreams.length) this.hasMoreData = false;
      }
    })
  }

}
