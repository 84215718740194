import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminProfileService {

  constructor(private http: HttpService) { }

  getProfile() {
    return this.http.getData('profile/get');
  }

}
export interface AdminProfile {
  _id: number;
  username: string;
  email: string;
  city: string;
  mobilenumber: string;
  address: string;
  profilePic: string;
  password: string;
  role: {
    name: string;
  }
}
