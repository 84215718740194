import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {
  public apiServer = `${environment.apiUrl}`;
  constructor() { }

  //register admin
  register: string = 'auth/register/';
  registerAdmin: string = this.register + 'admin';

  // Category
  category: string = 'category/';
  addCategory: string = this.category + 'create';
  getCategory: string = this.category + 'get';
  getCategoryById: string = this.category + 'getbyid/';
  updateCategory: string = this.category + 'update/';
  deleteCategory: string = this.category + 'delete/';

  //brand
  brand: string = 'brand/';
  getAllBrands: string = this.brand + 'get';

  //sub category
  subCate: string = 'subcategory/';
  subCategory: string = 'subcategory/get';
  addSubCategory: string = this.subCate + 'create';
  getSubCategoryById: string = this.subCate + 'getbyid/';
  updateSubCategory: string = this.subCate + 'update/';
  deleteSubCategory: string = this.subCate + 'delete/';

  // subsub
  subsubCate: string = 'subsubcategory/';
  getSubSubCategory: string = this.subsubCate + 'get';
  getSubSubCategoryByCatId: string = this.subsubCate + 'getbycatid/';
  createSubSubCategory: string = this.subsubCate + 'create';
  // getSubCategoryById: string = this.subsubCate + 'getbyid/';
  updateSubSubCategory: string = this.subsubCate + 'update/';
  deleteSubSUbCategory: string = this.subsubCate + 'delete/';

  // size format
  sizeFormat: string = 'sizeFormat/';
  getSizeFormat: string = this.sizeFormat + 'get';
  addSizeFormat: string = this.sizeFormat + 'create';
  updateSizeFormat: string = this.sizeFormat + 'update/';
  deleteSizeFormat: string = this.sizeFormat + 'delete/';
  // Material
  mate: string = 'material/';
  material: string = 'material/get';
  addMaterial: string = this.mate + 'create';
  getMaterialById: string = this.mate + 'getbyid/';
  updateMaterial: string = this.mate + 'update/';
  deleteMaterial: string = this.mate + 'delete/';
  // Color
  clr: string = 'color/';
  color: string = this.clr + 'get';
  addColor: string = this.clr + 'create';
  getColorById: string = this.clr + 'getbyid/';
  updateColor: string = this.clr + 'update/';
  deleteColor: string = this.clr + 'delete/';
  // itemDimention 
  itemDim: string = 'itemDimention/';
  itemDimention: string = this.itemDim + 'get';
  addItemDimention: string = this.itemDim + 'create';
  getItemDimentionById: string = this.itemDim + 'getbyid/';
  updateItemDimention: string = this.itemDim + 'update/';
  deleteItemDimention: string = this.itemDim + 'delete/';
  // FAQ's
  FaqBase: string = 'faq/';
  faq: string = this.FaqBase + 'get';
  addFaq: string = this.FaqBase + 'create';
  getFaqById: string = this.FaqBase + 'getbyid/';
  updateFaq: string = this.FaqBase + 'update/';
  deleteFaq: string = this.FaqBase + 'delete/';

  // Testimonials
  testimonialBase: string = 'testimonials/';
  testimonial: string = this.testimonialBase + 'get';
  addTestimonials: string = this.testimonialBase + 'create';
  getTestimonialsById: string = this.testimonialBase + 'getbyid/';
  updateTestimonials: string = this.testimonialBase + 'update/';
  deleteTestimonials: string = this.testimonialBase + 'delete/';
  // contactUS
  contactUsBase: string = "contactUs/";
  contactUs: string = this.contactUsBase + 'get';
  updateContactUs: string = this.contactUsBase + 'update/';
  deleteContactUs: string = this.contactUsBase + 'delete/';
  // Session
  sessionBase: string = "session/";
  getSession: string = this.sessionBase + 'get';
  addSession: string = this.sessionBase + 'create';
  updateSession: string = this.sessionBase + 'update/';
  deleteSession: string = this.sessionBase + 'delete/';
  // TermsAndConditions
  termsAndConditionsBase: string = "terms/conditions/";
  getTermsAndConditions: string = this.termsAndConditionsBase + 'get';
  addTermsAndConditions: string = this.termsAndConditionsBase + 'create';
  updateTermsAndConditions: string = this.termsAndConditionsBase + 'update/';
  deleteTermsAndConditions: string = this.termsAndConditionsBase + 'delete/';

  // Policy
  PolicyBase: string = "policy/";
  getPolicy: string = this.PolicyBase + 'get';
  addPolicy: string = this.PolicyBase + 'create';
  updatePolicy: string = this.PolicyBase + 'update/';
  deletePolicy: string = this.PolicyBase + 'delete/';
  // Customer
  CustomerBase: string = "user/";
  getCustomer: string = this.CustomerBase + 'get';
  addCustomer: string = this.CustomerBase + 'create/customer';
  updateCustomer: string = this.CustomerBase + 'update/';
  deleteCustomer: string = this.CustomerBase + 'delete/';
  // Seller
  SellerBase: string = "user/";
  getSeller: string = this.SellerBase + 'get/seller';
  addSeller: string = this.SellerBase + 'create/seller';
  updateSeller: string = this.SellerBase + 'update/';
  deleteSeller: string = this.SellerBase + 'delete/';

  //size
  size: string = 'sizechart/';
  getSize: string = this.size + 'get';

  // product
  product: string = 'product/';
  addProduct: string = this.product + 'create';
  getProducts: string = this.product + 'admin/get';
  deleteProduct: string = this.product + 'delete/';
  updateProduct: string = this.product + 'update/';

  //stock
  getStock: string = 'inventory/get';
  // oerders
  getOrder: string = 'order/get'
  // Size
  sizeBase: string = "size/";
  addSize: string = this.sizeBase + 'create';
  // getSize: string = this.sizeBase + 'get';
  updateSize: string = this.sizeBase + 'update/';
  deleteSize: string = this.sizeBase + 'delete/';

  //banner
  banner: string = 'bannerImage/';
  getBanners: string = this.banner + 'get';
  createBanner: string = this.banner + 'create';
  deleteBanner: string = this.banner + 'delete/';
  updateBanner: string = this.banner + 'update/';
  // store
  store: string = 'store/';
  getStore: string = this.store + 'get';
  updateStore: string = this.store + 'update/';
  deleteStore: string = this.store + 'delete/';
  createStore: string = this.store + 'create';

  //takeALook
  takeALook: string = 'takeLook/';
  getTakeALookImages: string = this.takeALook + 'get';
  updateTakeALookImage: string = this.takeALook + 'update/';
  deleteTakeALookImage: string = this.takeALook + 'delete/';
  addTakeALookImage: string = this.takeALook + 'create';

  //streams
  live: string = 'live/';
  getStreams: string = this.live + 'getLiveStreamingVideo'

  //dashboard
  dashboard: string = 'dashboard';
}
