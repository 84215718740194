import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { contactUs, StaticContentService } from '../service/static-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUs = [];
  closeResult: string
  addConatctUsForm: FormGroup;
  isSubmitted = false;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private staticContentService: StaticContentService) {
    // this.faqs = ConatctUsDB.faq;
  }

  public settings = {
    // delete: {
    //   confirmDelete: true,
    //   deleteButtonContent: 'Delete data',
    //   saveButtonContent: 'save',
    //   cancelButtonContent: 'cancel'
    // },
    // edit: {
    //   confirmSave: true,
    // },
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      delete: false,
      edit: false,
      add:false,
      position: 'right'
    },
    columns: {
      sr_no: {
        title: 'Sr. No.'
      },
      name: {
        title: 'Name'
      },
      fromEmail: {
        title: 'Email',
        type: 'html',
      },
      Description: {
        title: 'Message'
      },
    },
  };


  ngOnInit(): void {
    // this.createForm();
    this.getConatctUs();
  }
  // createForm() {
  //   this.addConatctUsForm = this.formBuilder.group({
  //     faQuestion: ['', [Validators.required]],
  //     description: ['', [Validators.required]]
  //   })
  // }

  get f() {
    return this.addConatctUsForm.controls;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.addConatctUsForm.reset();
    });
  }
  getConatctUs() {
    this.staticContentService.getContactUs().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success) {
          const newModData = []
          response.data.forEach((conObj, i) => {
            newModData.push({ name: conObj?.fullName || 'test', sr_no: i + 1, ...conObj })
          })
          this.contactUs = newModData;
        }
      }, error => {
        console.error(error);
      })
  }
  onSaveConfirm(event) {
    let param = {
      Description: event.newData.Description
    }
    this.staticContentService.updateContactUs(event.data._id, param).pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          this.modalService.dismissAll();
          this.getConatctUs()
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }
  onDeleteConfirm(event: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this ConatctUs?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.staticContentService.deleteContactUs(event.data._id).pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.success == true) {
              this.getConatctUs()
              this.contactUs = this.contactUs.filter(contact => contact._id !== event.data._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'ConatctUs has been deleted.',
                'success'
              )
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
}
