import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-buttons',
  templateUrl: './custom-buttons.component.html',
  styleUrls: ['./custom-buttons.component.scss']
})
export class CustomButtonsComponent implements OnInit {
  @Input() rowData!: any;
  @Output() click: EventEmitter<any> = new EventEmitter(this.rowData);
  @Output() onrowDataInit: EventEmitter<any> = new EventEmitter(this.rowData);
  constructor() { }

  ngOnInit(): void {
    this.onrowDataInit.emit(this.rowData);
  }

}
