import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomButtonsComponent } from 'src/app/shared/components/custom-buttons/custom-buttons.component';
import Swal from 'sweetalert2';
import { ProductService } from '../service/product.service';
@Component({
  selector: 'app-manage-stock',
  templateUrl: './manage-stock.component.html',
  styleUrls: ['./manage-stock.component.scss']
})
export class ManageStockComponent implements OnInit, OnDestroy, AfterViewInit {
  productList: Array<any> = []
  temp = [];
  updateProd = {} as any;
  searchTerm: string
  showSpinner: boolean = true;
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.getProducts();
  }
  ngAfterViewInit() {
  }

  public settings = {
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right',
      add: false,
      delete: false,
      edit: false
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      picture: {
        title: 'Images',
        type: 'html',
      },
      category: {
        title: 'Category '
      },
      subCategory: {
        title: 'Sub Category'
      },
      subsubCategory: {
        title: 'Sub Sub Category',
      },
      Favname: {
        title: 'Name',
      },
      size: {
        title: 'Size'
      },
      username: {
        title: 'Username',
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'delete')
              this.deleteMyFavs(row.rowData);
          });
        }
      },
    },
  };

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  search(event: any): void {
    this.productList = this.temp.filter((val) =>
      val?.category?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.subCategory?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.subsubCategory?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.Favname?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.username?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.size?.includes(event.target.value)
    );
  }

  private getProducts(): void {
    setTimeout(() => this.showSpinner = false, 800);
    this.productService.getProducts().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          const prodArr = []
          response.data.forEach((prod, i) => {
            let makeProd = { ...prod } as any;
            makeProd.category = prod?.catId?.name || "not found"
            makeProd.subCategory = prod?.subCatId?.name || "not found"
            makeProd.subsubCategory = prod?.subSubCatId?.name || "not found"
            makeProd.title = prod?.title || "not found"
            makeProd.sr_no = i + 1
            makeProd.picture = prod && prod?.images[0] && prod.images[prod.images.length - 1]?.url ? "<img  src=" + prod.images[prod.images.length - 1]?.url + " class='imgTable'>" : "<img  src=" + 'assets/images/prod.png' + " class='imgTable'>"
            makeProd.Favname = prod?.name || 'not found'
            makeProd.username = prod?.userId?.username || "not found"
            makeProd._id = prod?._id
            prodArr.push(makeProd)
            makeProd = {}
          })
          this.temp = prodArr;
          this.productList = prodArr;
        }
      }, error => {
        console.error(error);
      })
  }

  deleteMyFavs(rowData: any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this MyFav?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteProduct(rowData._id)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.productList = this.productList.filter(category => category._id !== rowData._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'MyFav has been deleted.',
                'success'
              )
              this.getProducts()
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
        )
      }
    })
  }

}
