import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
    providedIn: 'root'
})
export class StaticContentService {

    constructor(private urlsService: UrlsService, private http: HttpService) { }

    addFaq(data: any) {
        return this.http.postData(this.urlsService.addFaq, data);
    }
    getFaq() {
        return this.http.getData(this.urlsService.faq);
    }

    getFaqById(faqId: string) {
        return this.http.getData(this.urlsService.getFaqById + faqId);
    }

    updateFaq(faqId: string, data: any) {
        return this.http.putData(this.urlsService.updateFaq + faqId, data);
    }

    deleteFaq(faqId: string) {
        return this.http.deteleData(this.urlsService.deleteFaq + faqId);
    }
    // start the testimonial service
    addTestimonial(data: any) {
        return this.http.postData(this.urlsService.addTestimonials, data);
    }
    getTestimonial() {
        return this.http.getData(this.urlsService.testimonial);
    }

    getTestimonialById(testiMonialId: string) {
        return this.http.getData(this.urlsService.getTestimonialsById + testiMonialId);
    }

    updateTestimonial(testiMonialId: string, data: any) {
        return this.http.putData(this.urlsService.updateTestimonials + testiMonialId, data);
    }

    deleteTestimonial(testiMonialId: string) {
        return this.http.deteleData(this.urlsService.deleteTestimonials + testiMonialId);
    }

    // contactUs
    getContactUs() {
        return this.http.getData(this.urlsService.contactUs);
    }
    updateContactUs(contactId: string, data: any) {
        return this.http.putData(this.urlsService.updateContactUs + contactId, data);
    }

    deleteContactUs(contactId: string) {
        return this.http.deteleData(this.urlsService.deleteContactUs + contactId);
    }
    // TermsAndConditions
    getTermsAndConditions() {
        return this.http.getData(this.urlsService.getTermsAndConditions);
    }
    addTermsAndConditions(data: any) {
        return this.http.postData(this.urlsService.addTermsAndConditions, data);
    }
    updateTermsAndConditions(TcId: string, data: any) {
        return this.http.putData(this.urlsService.updateTermsAndConditions + TcId, data);
    }
    deleteTermsAndConditions(TcId: string) {
        return this.http.deteleData(this.urlsService.deleteTermsAndConditions + TcId);
    }
    // Policy
    // TermsAndConditions
    getPolicy() {
        return this.http.getData(this.urlsService.getPolicy);
    }
    addPolicy(data: any) {
        return this.http.postData(this.urlsService.addPolicy, data);
    }
    updatePolicy(PcId: string, data: any) {
        return this.http.putData(this.urlsService.updatePolicy + PcId, data);
    }
    deletePolicy(PcId: string) {
        return this.http.deteleData(this.urlsService.deletePolicy + PcId);
    }

}

export interface Faq {
    question: string,
    answer: string,
    _id: string
}

export interface Testimonial {
    name: string,
    description: string,
    _id: string
}

export interface contactUs {
    name: string,
    description: string,
    email: string,
    _id: string
}

