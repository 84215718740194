<div class="container mb-5">
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6 col-lg-3 py-0 px-4 mb-3" *ngFor="let item of allStreams">
            <div class="custom-store-img ">
                <video class="h-100 w-100 rounded-10" #video type="video/mp4" id="background-video" controls alt="...">
                    <source [src]="item" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center my-2">
        <div class="col-md-5 col-lg-4 col-sm-12 mx-auto" *ngIf="allStreams.length > 0 && hasMoreData">
            <button class="btn btn-secondary w-100 text-center" (click)="loadMoreList()">
                Load More
            </button>
        </div>
    </div>
</div>