<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Manage Order</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <input type='text' class="filter-ngx form-control" placeholder='Search...'
                            (keyup)='updateFilter($event)' />
                        <div id="batchDelete" class="orders-table custom-datatable">
                            <div class="table-responsive">
                                <ng2-smart-table [settings]="settings" [source]="order"></ng2-smart-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->