import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonLoaderComponent } from 'src/app/shared/components/common-loader/common-loader.component';
import Swal from 'sweetalert2';
import { ProductService } from '../service/product.service';
import { SubSubCategoriesService } from '../sub-sub-categories.service';

@Component({
  selector: 'app-size',
  templateUrl: './size.component.html',
  styleUrls: ['./size.component.scss']
})
export class SizeComponent implements OnInit {
  addSizeForm: FormGroup;
  subSubCategories: [] = []
  sizes: [] = []
  counter: number = 0;
  sizeArray: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private subSubCategoriesService: SubSubCategoriesService,
    private productService: ProductService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.createForm()
    this.getSubSubCategory();
  }

  createForm() {
    this.addSizeForm = this.formBuilder.group({
      subSubCategory: [''],
      country: [''],
      size: ['']
    })
  }

  private getSubSubCategory() {
    this.subSubCategoriesService.getSUbSUbCategories().subscribe(response => {
      this.subSubCategories = response.data
    })
  }


  public collectSize(newValue: any, from: string, oldValue?) {
    if (newValue != '') {
      this.counter = this.counter + 1
      const attachSize = document.getElementById('attach-size');
      // const icons = document.getElementById('icons');
      let ele = document.createElement('div');
      let newDiv = document.createElement('div');
      ele.textContent = newValue;
      ele.setAttribute('class', 'size-contain')
      ele.setAttribute('id', 'size-box' + this.counter);
      ele.innerHTML = `<span>${newValue}</span>`;
      newDiv.innerHTML = `<i id='editIcon${this.counter}' class="fa-solid fa-pencil"></i>` + `<i id='deleteIcon${this.counter}' class="fa-solid fa-trash"></i>`;
      attachSize.appendChild(ele);
      document.getElementById('size-box' + this.counter).appendChild(newDiv);
      document.getElementById('editIcon' + this.counter).style.cursor = 'pointer';
      document.getElementById('deleteIcon' + this.counter).style.cursor = 'pointer';
      if (from == 'add') {
        this.sizeArray.push(newValue);
      } else if (from == 'update') {
        this.sizeArray.forEach((element, index) => {
          if (element == oldValue) this.sizeArray.splice(index, 1);
        });
        this.sizeArray.push(newValue);
      }
      this.addEvent(newValue, this.counter);
      this.addSizeForm.controls.size.setValue('');
    }
  }

  private addEvent(value: any, counter) {
    let thisObj = this
    const attachSize = document.getElementById('attach-size');
    document.addEventListener('click', function (e: any) {
      if (e.target && e.target.id == 'editIcon' + counter) {
        let old_element = document.getElementById('size-box' + counter)
        old_element.remove();
        let newele = document.createElement('input');
        newele.setAttribute('type', 'text');
        newele.setAttribute('class', 'form-control');
        newele.setAttribute('id', 'new-form-control' + counter);
        newele.value = value;
        // newele.innerHTML = `<i id='saveIcon' class="fa-solid fa-floppy-disk"></i>`
        attachSize.appendChild(newele)
        let formControlId = document.getElementById('new-form-control' + counter);
        thisObj.addEventToFormControl(formControlId, counter, value);
      } else if (e.target && e.target.id == 'deleteIcon' + counter) {
        thisObj.sizeArray.forEach((element, index) => {
          if (element == value) thisObj.sizeArray.splice(index, 1);
        });
        let old_element = document.getElementById('size-box' + counter)
        old_element.remove();
      }
    });
  }
  addEventToFormControl(formControlId, counter, oldValue) {
    let thisObj = this;
    document.addEventListener('keyup', function (e: any) {
      if (e.isComposing || e.keyCode === 13) {
        if (e.target && e.target.id == 'new-form-control' + counter) {
          let editedFormControl: any = document.getElementById('new-form-control' + counter)
          thisObj.collectSize(e.target.value, 'update', oldValue);
          editedFormControl.remove();
        }
      }
    });
  }

  public addSize() {
    if (this.sizeArray.length > 0 && this.addSizeForm.value.subSubCategory != '') {
      let param = {
        sizes: this.sizeArray,
        sizeType: this.addSizeForm.value.country,
        subsubCatId: this.addSizeForm.value.subSubCategory
      }
      this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
      this.productService.addSize(param).subscribe(response => {
        if (response.body.success == true) {
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
    }
  }

}
