import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public user = new BehaviorSubject<any>('');
  // castUser = this.user.asObservable();
  constructor(private urlsService: UrlsService, private http: HttpService) { }

  addCategory(data: any) {
    return this.http.postFormData(this.urlsService.addCategory, data);
  }

  getCategory() {
    return this.http.getData(this.urlsService.getCategory);
  }

  getCategoryById(catId: string) {
    return this.http.getData(this.urlsService.getCategoryById + catId);
  }

  updateCategory(catId: string, data: any) {
    return this.http.putFormData(this.urlsService.updateCategory + catId, data);
  }

  deleteCategory(catId: string) {
    return this.http.deteleData(this.urlsService.deleteCategory + catId);
  }
  // Subcategory Add Services
  addSubCategory(data: any) {
    return this.http.postFormData(this.urlsService.addSubCategory, data);
  }
  // Subcategory update Services
  updateSubCategory(subCatId: string, data: any) {
    return this.http.putFormData(this.urlsService.updateSubCategory + subCatId, data);
  }
  // Subcategory delete Services
  deleteSubCategory(subCatId: string) {
    return this.http.deteleData(this.urlsService.deleteSubCategory + subCatId);
  }
  // Subcategory get Services
  getSubCategory() {
    return this.http.getData(this.urlsService.subCategory);
  }
  // Subcategory getById Services
  getByIdSubCategory(subCatId: string, data: any) {
    return this.http.putData(this.urlsService.getSubCategoryById + subCatId, data);
  }
  //getSize
  getSize() {
    return this.http.getData(this.urlsService.getSize);
  }

  getSizeByCatId(catId: string) {
    return this.http.getData('size/getbyCatId/' + catId);
  }

  getSizeChartByCatId(catId: string) {
    return this.http.getData('sizechart/getbyCatId/' + catId)
  }

  //add product
  addProduct(data: any) {
    return this.http.postFormData(this.urlsService.addProduct, data);
  }
  //get products
  getProducts() {
    return this.http.getData(this.urlsService.getProducts);
  }
  //get stock
  getStock() {
    return this.http.getData(this.urlsService.getStock);
  }

  deleteProduct(productId: string) {
    return this.http.deteleData(this.urlsService.deleteProduct + productId);
  }

  updateProduct(productId: string, data: any) {
    return this.http.putFormData(this.urlsService.updateProduct + productId, data);
  }

  addSizeFormat(data: any) {
    return this.http.postData(this.urlsService.addSizeFormat, data);
  }

  // size
  addSize(data: any) {
    return this.http.postFormData(this.urlsService.addSize, data);
  }

  addSizeChart(data: any) {
    return this.http.postFormData('sizechart/create', data);
  }
  // Color Update Services
  updateSize(colorId: string, data: any) {
    return this.http.putFormData(this.urlsService.updateSize + colorId, data);
  }

  updateSizeChart(id: string, data: any) {
    return this.http.putFormData('sizechart/update/' + id, data);
  }
  // color delete Services
  deleteSize(colorId: string) {
    return this.http.deteleData(this.urlsService.deleteSize + colorId);
  }

  deleteSizeChart(id: string) {
    return this.http.deteleData('/sizechart/delete/' + id)
  }
  getSeller() {
    return this.http.getData(this.urlsService.getSeller);
  }

}

export interface Category {
  code: string,
  name: string,
  _id: string
}

export interface SubCategory {
  catId: [{
    name: string,
    _id: string
  }],
  code: string,
  name: string,
  _id: string
}


