import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Category, ProductService } from '../service/product.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CommonLoaderComponent } from 'src/app/shared/components/common-loader/common-loader.component';
import { CustomButtonsComponent } from 'src/app/shared/components/custom-buttons/custom-buttons.component';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})

export class CategoryComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public categories: Array<Category> = []
  public addCategoryForm: FormGroup;
  public allCategories: Array<Category> = [];
  public isSubmitted = false;
  private imageFile: any;
  public searchTerm!: string;
  public readonly destroyer$: Subject<void> = new Subject();
  showSpinner: boolean = true;
  showUpdate: boolean = false;
  @ViewChild('content')
  content!: ElementRef;
  imageLink: any;
  rowData: any;
  title = 'Add Category';
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private productService: ProductService
  ) {
    // this.categories = categoryDB.category;
  }

  open(content, rowData?): void {
    if (rowData?.action == 'edit' || this.allCategories.length < 2) {
      if (rowData)
        this.patchValues(rowData)
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.showUpdate = false;
        this.title = 'Add Category';
        this.imageLink = '';
        this.f['categoryImage'].setValidators([Validators.required]);
        this.addCategoryForm.reset();
      });
    }
    else if (this.allCategories.length == 2) {
      Swal.fire({
        icon: 'error',
        text: 'Sorry..!! You can not add more than two categories.'
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public settings = {
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      image: {
        title: 'Picture',
        type: 'html',
      },
      name: {
        title: 'Name'
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'edit')
              this.editCategory(row);
            if (row.action == 'delete')
              this.deleteCategory(row.rowData);
          });
        }
      },
    },
  };

  ngOnInit() {
    this.getCategory();
    this.setUpForm();

  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private setUpForm(): void {
    this.addCategoryForm = this.formBuilder.group({
      categoryName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      categoryImage: ['', [Validators.required]]
    })
  }

  get f() {
    return this.addCategoryForm.controls;
  }

  patchValues(rowData) {
    this.imageLink = rowData?.rowData?.imgUrl
    this.addCategoryForm.patchValue({
      categoryName: rowData?.rowData?.name,
      description: rowData?.rowData?.description,
    })
  }

  // get categories
  getCategory() {
    setTimeout(() => this.showSpinner = false, 800);
    this.productService.getCategory()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success) {
          const allCate = []
          // this.allCategories = response.data;
          response.data.forEach((element, i) => {
            const obj = {
              name: element?.name,
              code: element?.code,
              sr_no: i + 1,
              image: element && element?.image ? "<img src=" + element?.image + " class='imgTable'>" : "<img  src=" + 'assets/images/no-img-available.jpg' + " class='imgTable'>",
              description: element?.description || 'no description',
              _id: element?._id,
              imgUrl: element?.image,
              images: element?.image
            }
            this.imageLink = element?.image;
            allCate.push(obj)
          })
          this.categories = allCate
          this.allCategories = this.categories.slice(0, 2);
          // banner: "<img src=" + element.banner[0] + " class='imgTable'>"
        }
      }, error => {
        console.error(error);
      })
  }

  // post category
  addCategory(value?): void {
    let formData = new FormData;
    formData.append('name', this.addCategoryForm.value.categoryName)
    formData.append('description', this.addCategoryForm.value.description)
    if (this.imageFile)
      formData.append('catImage', this.imageFile)
    this.isSubmitted = true;
    if (this.isSubmitted && this.addCategoryForm.valid) {
      this.modalService.open(CommonLoaderComponent, { centered: true, size: 'sm', windowClass: 'custom-loader' })
      if (value == 'add') {
        this.productService.addCategory(formData)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.getCategory();
              this.addCategoryForm.reset();
              this.f['categoryImage'].setValidators([Validators.required]);
              this.modalService.dismissAll();
              this.isSubmitted = false;
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
      else if (value == 'update') {
        this.productService.updateCategory(this.rowData?.rowData?._id, formData)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.modalService.dismissAll();
              Swal.fire({
                icon: 'success',
                text: response.body.message,
              });
              this.getCategory();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      }
    }
  }

  // put category
  editCategory(rowData?: any) {
    this.showUpdate = true;
    this.rowData = rowData;
    this.f['categoryImage'].clearValidators();
    this.title = 'Update Category';
    this.open(this.content, rowData)
  }

  uploadImage(event: any) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imageFile = event.srcElement.files[0];
      this.imageLink = reader.result;
    }
  }

  //delete by id
  deleteCategory(event: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteCategory(event.data._id)
          .pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.allCategories = this.allCategories.filter(category => category._id !== event.data._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'Category has been deleted.',
                'success'
              )
              this.getCategory();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
        )
      }
    })
  }
}
