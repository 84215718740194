import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../service/custmers.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
// import { CustomFilterComponent } from './custom-file-uploader-component'
import { CustomButtonsComponent } from '../custom-buttons/custom-buttons.component';
@Component({
  selector: 'app-cutomers',
  templateUrl: './cutomers.component.html',
  styleUrls: ['./cutomers.component.scss']
})
export class CutomersComponent implements OnInit {
  customers = [];
  closeResult: string;
  addCustomerForm: FormGroup;
  isSubmitted = false;
  temp = [];
  searchTerm: string
  blockButton: any;
  unblockButton: any;
  counter = 0;
  showSpinner: boolean = true;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private cdr: ChangeDetectorRef
  ) {
    // this.customers = CustomersDB.customers;
  }

  public settings = {
    edit: {
      confirmSave: true,
    },
    pager: {
      display: true,
      perPage: 5,
    },

    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      name: {
        title: 'Name',
        type: 'html',
      },
      email: {
        title: 'Email'
      },
      contact: {
        title: 'Contact Number'
      },
      profilePic: {
        title: 'Picture',
        type: 'html',
      },
      isBlocked: {
        title: "Active/InActive"
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.isDelete == true)
              this.deleteUser(row.rowData)
            if (row.isDelete == false)
              this.changeUserStatus(row.rowData)
          });
        }
      },
    },
  };

  ngOnInit(): void {
    this.getCustomer();
  }

  changeUserStatus(rowData: any) {
    if (rowData.isBlocked == 'Active') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure you want to deactivate this user?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.blockUser(rowData._id).subscribe(response => {
            if (response.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.message,
              });
              this.getCustomer();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled'
          )
        }
      })
    } else if (rowData.isBlocked != 'Active') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure you want to activate this user?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.unblockUser(rowData._id).subscribe(response => {
            if (response.success == true) {
              Swal.fire({
                icon: 'success',
                text: response.message,
              });
              this.getCustomer();
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled'
          )
        }
      })
    }
  }

  search(event) {
    this.customers = this.temp.filter((val: any) =>
      val?.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.isBlocked?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.contact?.includes(event.target.value)
    );
  }

  getCustomer(param?) {
    setTimeout(() => this.showSpinner = false, 800);
    this.customerService.getCustomer().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        const customerDataArr = []
        if (response.success) {
          response.data.forEach((customer, i) => {
            // prod && prod?.images[0] && prod.images[prod.images.length - 1]?.url ? "<img  src=" + prod.images[prod.images.length - 1]?.url + " class='imgTable'>" : "<img  src=" + 'assets/images/prod.png' + " class='imgTable'>"
            let newObj = {};
            newObj = {
              sr_no: i + 1,
              name: customer.username,
              _id: customer._id,
              isBlocked: customer.isBlocked ? "InActive" : "Active",
              email: customer.email,
              contact: customer.mobilenumber,
              profilePic: customer?.profilePic != '' ? `<img src=${customer?.profilePic} class='imgTable'>` : "<img  src=" + 'assets/images/user.png' + " class='imgTable'>"
            };
            customerDataArr.push(newObj)
          })
          this.temp = customerDataArr;
          this.customers = customerDataArr;
        }
      }, error => {
        console.error(error);
      })
  }

  deleteUser(data: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this User?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.customerService.deleteCustomer(data._id).pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.customers = this.customers.filter(Customer => Customer._id !== data._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'User has been deleted.',
                'success'
              )
              this.getCustomer()
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled'
        )
      }
    })
  }
}
