import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubCategoriesService {
  private apiUrl = `${environment.apiUrl}/subcategory/`;
  constructor(
    private http: HttpClient,
  ) { }

  getSubCategoriesFromCatId(catId: string) {
    let url = `${this.apiUrl}getbycatid/${catId}`;
    return this.http.get(url).pipe();
  }

}
