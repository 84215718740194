<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Stores</h5>
                </div>
                <div class="card-body">
                    <!-- <form class="needs-validation" [formGroup]="storeForm" (ngSubmit)="addStore()" novalidate>
                        <div class="add-product-form">

                            <div class="form-group row">
                                <label for="exampleFormControlSelect1" class="col-xl-3 col-sm-4 mb-0">SellerName:</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                    <select class="form-control digits" [formControl]="storeForm.controls['catId']"
                                        id="exampleFormControlSelect1">
                                        <option *ngFor="let seller of sellerList" [value]="seller.value">{{seller.title}}</option>
                                    </select>
                                    <div *ngIf="((storeForm.controls.storeName.touched || isSubmit) && storeForm.controls.catId.errors?.required)"
                                        class="text text-danger">
                                        Select Sellet is required.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Shop Name
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                    <input class="form-control " id="validationCustom01" type="text"
                                        [formControl]="storeForm.controls['storeName']" required>
                                    <div *ngIf="((storeForm.controls.storeName.touched || isSubmit) && storeForm.controls.storeName.errors?.required)"
                                        class="text text-danger">
                                        Title Name is required.
                                    </div>
                                </div>
                            </div>



                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">First Name
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['firstName']" required>
                                <div *ngIf="((storeForm.controls.firstName.touched || isSubmit) && storeForm.controls.firstName.errors?.required)"
                                    class="text text-danger">
                                    First Name is required.
                                </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Last Name
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['lastName']">
                                
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Address Line1
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['addressLine1']" required>
                                <div *ngIf="((storeForm.controls.addressLine1.touched || isSubmit) && storeForm.controls.addressLine1.errors?.required)"
                                    class="text text-danger">
                                    Address line 1 is required.
                                </div>
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Address Line2
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['addressLine2']">
                                
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">City
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['city']" required>
                                <div *ngIf="((storeForm.controls.city.touched || isSubmit) && storeForm.controls.city.errors?.required)"
                                    class="text text-danger">
                                    City is required.
                                </div>
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">State
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['state']" required>
                                <div *ngIf="((storeForm.controls.state.touched || isSubmit) && storeForm.controls.state.errors?.required)"
                                    class="text text-danger">
                                    State is required.
                                </div>
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Country
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['country']" required>
                                <div *ngIf="((storeForm.controls.country.touched || isSubmit) && storeForm.controls.country.errors?.required)"
                                    class="text text-danger">
                                    Country is required.
                                </div>
                                </div>
                            </div> -->

                            <!-- <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Pincode
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['pincode']" required>
                                <div *ngIf="((storeForm.controls.pincode.touched || isSubmit) && storeForm.controls.pincode.errors?.required)"
                                    class="text text-danger">
                                    Pincode is required.
                                </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Website
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                <input class="form-control " id="validationCustom01"
                                    type="text" [formControl]="storeForm.controls['website']">
                                
                                </div>
                            </div> -->

                            <!-- <div class="form-group row"> -->
                                <!-- <label for="validationCustom02" class="col-xl-3 col-sm-4 mb-0">Shop Image :</label>
                                <input class="form-control col-xl-8 col-sm-7" id="validationCustom02" type="file"
                                    (change)="getFile($event)"> -->
                                <!-- <div *ngIf="(storeForm.controls.price.touched || isSubmit) && storeForm.controls.price.errors?.required"
                                    class="text text-danger">
                                    Product Price is required.
                                </div> -->
                            <!-- </div> -->
                            <!-- <div class="form-group row">
                                <label class="col-xl-3 col-sm-4" style="position: relative;top:-25px">Description
                                    :</label>
                                <div class="col-xl-8 col-sm-7 pl-0 pr-0">
                                    <textarea [formControl]="storeForm.controls['description']" style="width: 100%;"
                                        rows="4"></textarea>
                                </div>
                            </div> -->
                        <!-- </div>
                        <div class="form-group row mb-0">
                            <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                                <button type="submit" class="btn btn-primary mr-1">Add</button>
                                <button type="button" class="btn btn-light">Discard</button>
                            </div>
                        </div>
                    </form> --> -->
                    <!-- <br> -->
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <!-- <ng2-smart-table [settings]="settings" [source]="stores"></ng2-smart-table> -->
                            <ng2-smart-table [settings]="settings" [source]="stores"
                                (deleteConfirm)="onDeleteConfirm($event)" (editConfirm)="onSaveConfirm($event)">
                            </ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->