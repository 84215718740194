import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CutomersComponent } from './cutomers/cutomers.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomButtonsComponent } from './custom-buttons/custom-buttons.component';


@NgModule({
  declarations: [CutomersComponent, CustomButtonsComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedModule
  ]
})
export class CustomersModule { }
