import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/users', title: 'Users', type: 'link' },
			]
		},
		{
			title: 'MyFavs', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/myfavs/physical/category', title: 'Category', type: 'link' },
				{ path: '/myfavs/physical/sub-category', title: 'Sub Category', type: 'link' },
				{ path: '/myfavs/physical/sub-sub-category', title: 'Sub Sub Category', type: 'link' },
				{ path: '/myfavs/physical/size-chart', title: 'Size Chart', type: 'link' },
				// { path: '/myfavs/physical/size', title: 'Size', type: 'link' },
				{ path: '/myfavs/physical/manage-stock', title: 'Manage MyFavs', type: 'link' },
			]
		},
		{
			title: 'Static Content', icon: 'file-text', type: 'sub', active: false, children: [
				{ path: '/static-content/terms-and-conditions', title: 'Terms And Conditions', type: 'link' },
				{ path: '/static-content/privacy-policy', title: 'Privacy Policy', type: 'link' },
			]
		},
		{
			title: 'Banner', icon: 'film', type: 'sub', active: false, children: [
				{ path: '/banners/banners', title: 'Banner', type: 'link' },
			]
		},
		{
			title: 'Report', icon: 'flag', type: 'sub', active: false, children: [
				{ path: '/report/user-report-list', title: 'User Report', type: 'link' },
				{ path: '/report/myfavs-report-list', title: 'MyFavs Report', type: 'link' },
			]
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
