import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { doughnutData, pieData } from '../../shared/data/chart';
import { DashboardService } from './service/dashboard.service';
declare const CanvasJS: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  public productList = []
  public orderList = []
  public cardDetails;
  objectKeys: any;
  UserGraphValues: any[] = [];
  productGraphValues: any[] = [];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private readonly destroyer$: Subject<void> = new Subject();
  userDps: any[] = [];
  productDps: any[] = [];
  constructor(private dashboardService: DashboardService) {
    Object.assign(this, { doughnutData, pieData })
  }

  dataArray: any = [];

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    this.getCardDetails();
  }

  getCardDetails() {
    this.dashboardService.getCardDetails().subscribe(response => {
      if (response.success == true) {
        this.UserGraphValues = response.data.graph?.monthWiseUser[0]?.monthWiseData;
        this.productGraphValues = response.data.graph?.monthWiseProduct[0]?.monthWiseData;
        this.cardDetails = response.data;
        this.objectKeys = Object.keys;
        this.getUserChartData();
        this.getProductChartData();
      }
    })
  }

  getUserChartData() {
    var chart = new CanvasJS.Chart("userChartContainer",
      {
        animationEnabled: true,
        title: {
          text: "Total Users"
        },
        axisX: {
          // interval: 0,
          includeZero: true,
        },
        axisY: {
          includeZero: true,
          gridThickness: 0,
        },
        data: [
          {
            type: "splineArea",
            markerSize: 3,
            color: "rgb(30 48 212 / 80%)",
            xValueType: "dateTime",
            dataPoints: this.userDps
          }
        ]
      });

    this.UserGraphValues?.forEach((value: any, i) => {
      this.userDps.push({
        x: value.month,
        y: value.usersCount,
        label: this.months[value.month - 1],
        toolTipContent: `Month: ${this.months[value.month - 1]}, Users: ${value.usersCount}`
      })
    })
    chart.options.axisX.valueFormatString = 'MMMM';
    chart.render();
  }

  getProductChartData() {
    var chart = new CanvasJS.Chart("productsChartContainer",
      {
        animationEnabled: true,
        title: {
          text: "Total Products"
        },
        axisX: {
          // interval: 0,
          includeZero: true,
        },
        axisY: {
          gridThickness: 0,
          includeZero: true,
        },
        data: [
          {
            type: "splineArea",
            markerSize: 3,
            xValueType: "dateTime",
            color: "rgb(30 48 212 / 80%)",
            dataPoints: this.productDps
          }
        ]
      });

    this.productGraphValues?.forEach((value: any, i) => {
      this.productDps.push({
        x: value.month,
        y: value.productCount,
        label: this.months[value.month - 1],
        toolTipContent: `Month: ${this.months[value.month - 1]}, Products: ${value.productCount}`
      })
      chart.options.axisX.valueFormatString = 'MMMM';
    })
    chart.render();
  }

}
