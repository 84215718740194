import { Component, OnInit } from '@angular/core';
// import { StoresDB } from 'src/app/shared/tables/stores';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoreService } from '../service/store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductService } from '../../products/service/product.service';
// import { ProductService } from '../service/product.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  stores = [];
  isSubmit: boolean = false
  sellerList = []
  storeForm: FormGroup;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private storeService: StoreService, private formBuilder: FormBuilder, private productService: ProductService) {
    // this.stores = StoresDB.stores;
  }

  public settings = {
    delete: {
      confirmDelete: false,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    edit: {
      confirmSave: false,
    },
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      store_logo: {
        title: 'Store Logo',
        type: 'html',
      },
      store_name: {
        title: 'Store Name'
      },
      seller_name: {
        title: 'seller'
      },
      accountNumber: {
        title: 'A/C Number'
      },
      bsb: {
        title: 'bsb'
      },
      accountName: {
        title: 'AccountName'
      }
    }
  };


  ngOnInit(): void {
    this.getStore()
    this.getSeller()

    this.createForm();
  }
  getSeller() {
    this.productService.getSeller().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {

        if (response.success == true) {
          // this.sellerData = response.data;
          const makeList = []
          response.data.forEach((seller, i) => {
            const obj = {} as any
            obj.value = seller._id;
            obj.title = seller.fullName;
            this.sellerList.push(obj)
            // this.settings.columns.seller_name.editor.config.list.push(obj);
            // this.settings = Object.assign({}, this.settings);
          })
          if (makeList.length > 0) {
            // this.sellerData = makeList;
            // return this.sellerData
            // this.settings.columns.userId.editor.config.list.push(l);
            // this.settings = Object.assign({}, this.settings);
          }
        }
      }, error => {
        console.error(error);
      })
  }
  getStore() {
    this.storeService.getStore().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success) {
          const storeArr = []
          // this.stores = response.data;
          response.data.data.forEach((store, i) => {
            const strObj = { ...store } as any;
            strObj.store_logo = store && store.storeLogoImage ? "<img src=" + store.storeLogoImage + " class='imgTable'>" : "nologo"
            strObj.seller_name = store?.userId?.fullName || 'not found'
            strObj.store_name = store?.storeName || 'not found'
            strObj.accountNumber = store?.userId?.sellerDetails?.accountNumber || 'not found'
            strObj.bsb = store?.userId?.sellerDetails?.bsb || 'not found'
            strObj.accountName = store?.userId?.sellerDetails?.accountName || 'not found'
            // strObj.no_of_items =
            //   strObj.no_of_sessions =

            const cloneObi = { sr_no: i + 1, ...strObj }
            storeArr.push(cloneObi)
          })
          this.stores = storeArr;
        }
      }, error => {
        console.error(error);
      })
  }
  createForm() {
    this.storeForm = this.formBuilder.group({
      // storeLogoImage: [''],
      // storeHomeImage: [''],
      // name: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      description: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      // password: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      userId: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),]],
      city: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      country: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      state: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]],
      storeName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]]
    })
  }
  onDeleteConfirm(event) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this product?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.storeService.deletStore(event.data._id)
          .subscribe(response => {
            if (response.success == true) {
              this.stores = this.stores.filter(str => str._id !== event.data._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'store has been deleted.',
                'success'
              )
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  onSaveConfirm(event) {
    let param = {
      storeName: event.newData.store_name
    }
    this.storeService.updateStore(event.data._id, param).subscribe(response => {
      if (response.body.success == true) {
        Swal.fire({
          icon: 'success',
          text: 'store updated',
        });
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        text: error,
      })
    })
  }
  addStore(): void {

    let param = {
      storeName: this.storeForm.value.storeName,
      email: this.storeForm.value.email,
      phone: this.storeForm.value.contact,
      city: this.storeForm.value.city,
      state: this.storeForm.value.state,
      country: this.storeForm.value.country,
      userId: this.storeForm.value.userId
    }
    this.isSubmit = true;
    this.storeService.addStore(param).pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response) {
          // this.
          // this.modalService.dismissAll();
          Swal.fire({
            icon: 'success',
            text: "store created successfully",
          });
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }
}
