<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header pb-3">
                    <h5>Privacy Policy</h5>
                </div>
                <div class="card-body py-3">
                    <div class="form-body mx-auto">
                            <form class="needs-validation" [formGroup]="addPrivacyPolicyForm">
                                <label for="description" class="mb-1">Description</label>
                                <div class="editor-container">
                                    <div class="editControls">
                                        <div class='btn-group'>
                                            <button type="button" class="btn p-2" (click)="setStyle('bold')">
                                                <b>B</b>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('italic')">
                                                <em><b>I</b></em>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('underline')">
                                                <u><b>U</b></u>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('strikeThrough')">
                                                <s>abc</s>
                                            </button>
                                        </div>
                                        <div class='btn-group'>
                                            <button type="button" class="btn p-2" (click)="setStyle('justifyLeft')">
                                                <i class='icon-align-left'></i>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('justifyCenter')">
                                                <i class='icon-align-center'></i>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('justifyRight')">
                                                <i class='icon-align-right'></i>
                                            </button>
                                            <button type="button" class="btn p-2" (click)="setStyle('justifyFull')">
                                                <i class='icon-align-justify'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div #editor class="editor w-100" [(innerHtml)]="description" id="editor"
                                            contenteditable></div>
                                    </div>
                                </div>
                            </form>
                        <div class="modal-footer border-0 py-3">
                            <button type="button" class="btn btn-primary" *ngIf="!showButton"
                                (click)="addPolicy()">Add</button>
                            <button type="button" class="btn btn-primary" *ngIf="showButton"
                                (click)="updatePolicy()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->