import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { SubSubCategoryComponent } from './sub-sub-category/sub-sub-category.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { ManageStockComponent } from './manage-stock/manage-stock.component';
import { SizeComponent } from './size/size.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'physical/category',
        component: CategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'physical/sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'physical/sub-sub-category',
        component: SubSubCategoryComponent,
        data: {
          title: "Sub Sub Category",
          breadcrumb: "Sub Sub Category"
        }
      },
      {
        path: 'physical/size-chart',
        component: SizeChartComponent,
        data: {
          title: "Size Chart",
          breadcrumb: "Size Chart"
        }
      },
      {
        path: 'physical/size',
        component: SizeComponent,
        data: {
          title: "Size",
          breadcrumb: "Size"
        }
      },
      {
        path: 'physical/manage-stock',
        component: ManageStockComponent,
        data: {
          title: "MANAGE FAV'S",
          breadcrumb: "MANAGE FAV'S"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
