<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card pb-4">
                <div class="card-header">
                    <h5>MyFavs Size</h5>
                </div>
                <div class="card-body w-50 mx-auto border m-3">
                    <form class="needs-validation" [formGroup]="addSizeForm">
                        <div class="form-group">
                            <label for="subSubCategory" class="mb-1">Select Sub Sub Category :</label>
                            <select class="form-select text-14 form-control" formControlName="subSubCategory"
                                id="subSubCategory">
                                <option selected value="" disabled>Select Sub Sub Category</option>
                                <option *ngFor="let item of subSubCategories" [value]="item._id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="country" class="mb-1">Select Country :</label>
                            <select class="form-select text-14 form-control" formControlName="country" id="country">
                                <option selected value="" disabled>Select Country</option>
                                <option value="US">US</option>
                                <option selected value="UK">UK</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="size" class="mb-1">Enter Size :</label>
                            <div class="input-group">
                                <input class="form-control" #size id="size" formControlName="size" type="text">
                                <div class="input-group-append">
                                    <span class="input-group-text px-2 py-0" role="button" id="sizeAppend"
                                        (click)="collectSize(size.value, 'add')">
                                        <span>Add</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="attach-size" class="container-box">
                        </div>
                    </form>
                    <div>
                        <button type="button" class="btn btn-primary" (click)="addSize()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>