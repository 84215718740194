import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  constructor(private urlsService: UrlsService, private http: HttpService) { }

  getStreamVideos(pageNo: number, perPage: number) {
    return this.http.getData(this.urlsService.getStreams + '?page=' + pageNo + '&perPage=' + perPage);
  }
}
