<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>MyFavs Size Chart</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <select class="form-select text-14 form-control w-25 mb-4" (change)="onCategorySelect($event)">
                            <option selected value="all">all</option>
                            <option *ngFor="let item of categories" [value]="item._id">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="btn-popup pull-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                                data-target="#exampleModal" (click)="open(content)">Add Size Chart</button>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Size Chart</h5>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form class="needs-validation" [formGroup]="addSizeChartForm">
                                        <div class="form">
                                            <div class="form-group">
                                                <label for="title" class="mb-1">Title :</label>
                                                <input class="form-control" id="title" formControlName="title"
                                                    type="text">
                                                <div *ngIf="(f['title'].invalid && f['title'].touched || f['title'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['title'].errors?.['required']">Please enter
                                                        title.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="mx-auto mb-1" for="category">Select Category
                                                    :</label>
                                                <select class="form-select text-14 form-control" formControlName="catId"
                                                    id="category">
                                                    <option selected value="" disabled>Select Category</option>
                                                    <option *ngFor="let item of categories" [value]="item._id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="isSubmitted || (f['catId'].invalid && f['catId'].touched || f['catId'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['catId'].errors?.['required']">Please select
                                                        category.<br></small>
                                                </div>
                                            </div>
                                            <div class="form-group" *ngIf="!showUpdate">
                                                <label for="banner" class="mb-1">Select Size Chart :</label>
                                                <input class="form-control" multiple accept="image/*" #banner
                                                    id="banner" formControlName="banner" type="file"
                                                    (change)="uploadBanner($event)">
                                                <div *ngIf="(f['banner'].invalid && f['banner'].touched || f['banner'].dirty)"
                                                    class="mt-1">
                                                    <small class="text-danger"
                                                        *ngIf="f['banner'].errors?.['required']">Please choose Size
                                                        image.</small>
                                                </div>
                                            </div>
                                            <div class="row px-3" *ngIf="showUpdate">

                                                <div class="image-container position-relative d-inline-block mx-2 mb-3">
                                                    <input type="file" #bannerImage id="bannerImage"
                                                        (change)="uploadBanner($event)" formControlName="banner"
                                                        accept="image/*" hidden>
                                                    <div class="h-100 w-100 d-flex justify-content-center image-box align-items-center"
                                                        [ngStyle]="{'background-image': imageLink ? 'url(' + imageLink + ')' : 'url(' + '' + ')' }">
                                                        <app-feather-icons [icon]="'upload'"
                                                            (click)="bannerImage.click()" class="text-secondary">
                                                        </app-feather-icons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" *ngIf="!showUpdate"
                                        (click)="addSizeChart('add')">Save</button>
                                    <button type="button" class="btn btn-primary" *ngIf="showUpdate"
                                        (click)="addSizeChart('update')">Update</button>
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                        (click)="modal.dismiss('Cross click')">{{ showUpdate?'Cancel':'Close' }}</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table *ngIf="!showSpinner" [settings]="settings" [source]="banners">
                            </ng2-smart-table>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" [class.d-none]="!showSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>