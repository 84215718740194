<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>FAQs</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open(content)">Add FAQ</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Add FAQ</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="needs-validation">
                                    <div class="form" [formGroup]="addFaqForm">
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">FAQ Question :</label>
                                            <input class="form-control" id="validationCustom01"
                                                formControlName="faQuestion" type="text">
                                            <div *ngIf="isSubmitted || (f['faQuestion'].invalid && f['faQuestion'].touched || f['faQuestion'].dirty)"
                                                class="mt-1">
                                                <small class="text-danger"
                                                    *ngIf="f['faQuestion'].errors?.['required']">Name
                                                    is required<br></small>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">FAQ Description :</label>
                                            <textarea class="form-control text-14 border form-control-lg"
                                                maxlength="150" formControlName="description" id="description"
                                                rows="3"></textarea>
                                            <div *ngIf="isSubmitted || (f['description'].invalid && f['description'].touched || f['description'].dirty)"
                                                class="mt-1">
                                                <small class="text-danger"
                                                    *ngIf="f['description'].errors?.['required']">Description
                                                    is required<br></small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="addFaq()">Save</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>
                    </div>
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" [source]="faqs" (deleteConfirm)="onDeleteConfirm($event)" (editConfirm)="onSaveConfirm($event)"></ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->