import { Routes } from '@angular/router';
import { AuthGuard } from '../service/auth.guard';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myfavs',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "MyFavs"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: "Users"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stores',
    loadChildren: () => import('../../components/stores/stores.module').then(m => m.StoresModule),
    data: {
      breadcrumb: "Stores"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'static-content',
    loadChildren: () => import('../../components/static-content/static-content.module').then(m => m.StaticContentModule),
    data: {
      breadcrumb: "Static Content"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'banners',
    loadChildren: () => import('../../components/banners/banner.module').then(m => m.BannerModule),
    data: {
      breadcrumb: "Banner"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('../../components/report/report.module').then(m => m.ReportModule),
    data: {
      breadcrumb: "Report"
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    },
    canActivate: [AuthGuard]
  },
];