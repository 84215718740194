<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products Category</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open(content)">Add Category</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{title}}</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="needs-validation" [formGroup]="addCategoryForm">
                                    <div class="form">
                                        <div class="form-group" *ngIf="!showUpdate">
                                            <label for="categoryImage" class="mb-1"> Add Image</label>
                                            <!-- <div class="image-container"> -->
                                            <input type="file" class="form-control" (change)="uploadImage($event)"
                                                #categoryImage id="categoryImage" formControlName="categoryImage"
                                                accept="image/*" />

                                            <div *ngIf="isSubmitted || (f['categoryImage'].invalid && f['categoryImage'].touched || f['categoryImage'].dirty)"
                                                class="mt-1">
                                                <small class="text-danger"
                                                    *ngIf="f['categoryImage'].errors?.['required']">Please upload image
                                                    name.<br></small>
                                            </div>
                                        </div>

                                        <div class="row px-3" *ngIf="showUpdate">
                                            <div class="image-container position-relative d-inline-block mx-2 mb-3">
                                                <input type="file" #catImage id="catImage"
                                                    (change)="uploadImage($event)" formControlName="categoryImage"
                                                    accept="image/*" hidden>
                                                <div class="h-100 w-100 d-flex justify-content-center image-box align-items-center"
                                                    [ngStyle]="{'background-image': imageLink ? 'url(' + imageLink + ')' : 'url(' + '' + ')' }">
                                                    <app-feather-icons [icon]="'upload'" (click)="catImage.click()"
                                                        class="text-secondary">
                                                    </app-feather-icons>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="categoryName" class="mb-1">Category Name :</label>
                                            <input class="form-control" app-restrict-input="integer" id="categoryName"
                                                formControlName="categoryName" type="text">
                                            <div *ngIf="isSubmitted || (f['categoryName'].invalid && f['categoryName'].touched || f['categoryName'].dirty)"
                                                class="mt-1">
                                                <small class="text-danger"
                                                    *ngIf="f['categoryName'].errors?.['required']">Please enter category
                                                    name.<br></small>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="description" class="mb-1">Description :</label>
                                            <textarea class="form-control border text-14 form-control-lg"
                                                maxlength="150" formControlName="description" id="description" rows="3"
                                                placeholder="Description"></textarea>
                                            <div *ngIf="isSubmitted || (f['description'].invalid && f['description'].touched || f['description'].dirty)"
                                                class="mt-1">
                                                <small class="text-danger"
                                                    *ngIf="f['description'].errors?.['required']">Please enter
                                                    description.<br></small>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" *ngIf="!showUpdate"
                                    (click)="addCategory('add')">Save</button>
                                <button type="button" class="btn btn-primary" *ngIf="showUpdate"
                                    (click)="addCategory('update')">Update</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">{{ showUpdate?'Cancel':'Close' }}</button>
                            </div>
                        </ng-template>
                    </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table *ngIf="!showSpinner" [settings]="settings" [source]="allCategories">
                            </ng2-smart-table>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" [class.d-none]="!showSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->