import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Testimonial, StaticContentService } from '../../static-content/service/static-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  testimonials = [];
  closeResult: string;
  isSubmitted = false;
  addTestimonialForm: FormGroup;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private staticContentService: StaticContentService) {
    // this.testimonials = TestimonialsDB.testimonials;
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel'
    },
    edit: {
      confirmSave: true,
    },
    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
      position: 'right'
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      name: {
        title: 'Testimonial name'
      },
      description: {
        title: 'Description'
      },
    },
  };

  ngOnInit(): void {
    this.createForm();
    this.getTestimonial()
  }
  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  createForm() {
    this.addTestimonialForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      testimonial: ['', [Validators.required]],
    })
  }

  get f() {
    return this.addTestimonialForm.controls;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.addTestimonialForm.reset();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getTestimonial() {
    this.staticContentService.getTestimonial().pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success) {
          // this.testimonials = response.data;
          const newArray = []
          response.data.forEach((element, i) => {
            let param = {
              sr_no: i + 1,
              ...element
            }
            newArray.push(param)
            // this.colorList.push(param);
          })

          if (newArray.length) {
            this.testimonials = newArray;
          }
        }
      }, error => {
        console.error(error);
      })
  }
  addTestimonial(): void {
    let param = {
      name: this.addTestimonialForm.value.name,
      description: this.addTestimonialForm.value.testimonial
    }
    this.isSubmitted = true;
    this.staticContentService.addTestimonial(param).pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.body.success == true) {
          this.getTestimonial()
          this.modalService.dismissAll();
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }
  onSaveConfirm(event) {
    let param = {
      name: event.newData.name,
      description: event.newData.description
    }
    this.staticContentService.updateTestimonial(event.data._id, param).pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.body.success == true) {
          this.modalService.dismissAll();
          this.getTestimonial()
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }
  onDeleteConfirm(event: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this testimonial?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.staticContentService.deleteTestimonial(event.data._id).pipe(takeUntil(this.destroyer$))
          .subscribe(response => {
            if (response.body.success == true) {
              this.getTestimonial()
              this.testimonials = this.testimonials.filter(testimonial => testimonial._id !== event.data._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'Testimonial has been deleted.',
                'success'
              )
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
}
